import * as React from 'react';
import {
    Breadcrumb,
    ResourceBreadcrumbItems,
} from '@react-admin/ra-navigation';

const CustomBreadcrumb = () => {
    return (
        <Breadcrumb
            sx={{
                fontSize: 'small',
                // Display the Breadcrumb over the custom Layout of some pages by adding a zIndex and a maxWidth
                // @see "src/products/ProductList.tsx" or "src/visitors/VisitorList.tsx"
                maxWidth: '700px',
                zIndex: 1,
                '& a': {
                    pointerEvents: 'visible',
                },
            }}
        >
            <ResourceBreadcrumbItems />
        </Breadcrumb>
    );
};

export default CustomBreadcrumb;
