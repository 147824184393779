import { fetchUtils } from 'ra-core';
import simpleRestProvider from 'ra-data-simple-rest';

export default (apiUrl: string, httpClient = fetchUtils.fetchJson) => {
    const basicDataProvider = simpleRestProvider(apiUrl, httpClient);
    return {
        ...basicDataProvider,
        delete: (resource, params) => {
            if (resource == 'trader_delete_from_bench') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/traders/${params.id}/delete_from_bench`,
                        {
                            method: 'POST',
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'slot_orders') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/slots/${params.previousData.slot_id}/orders/${params.id}`,
                        {
                            method: 'DELETE',
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else {
                return basicDataProvider.delete(resource, params);
            }
        },
        update: (resource, params) => {
            if (resource == 'slot_orders') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/slots/${params.data.slot_id}/orders/${params.id}`,
                        {
                            method: 'PUT',
                            body: JSON.stringify(params.data),
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_portfolio_config') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_portfolio_launch/${params.data.id}/update_config`,
                        {
                            method: 'PUT',
                            body: JSON.stringify(params.data),
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'calendar_events') {
                const {
                    data: { instrument_group_codes, ...restData },
                    ...rest
                } = params;
                delete restData.event_group;
                return Promise.resolve(
                    httpClient(`${apiUrl}/calendar_events/${params.data.id}`, {
                        method: 'PUT',
                        body: JSON.stringify(restData, rest),
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_trader_config') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_trader/${params.data.id}/update_config`,
                        {
                            method: 'PUT',
                            body: JSON.stringify(params.data),
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else {
                return basicDataProvider.update(resource, params);
            }
        },
        create: (resource, params) => {
            if (resource == 'portfolio_trader_switch_on') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/portfolio/${params.data.portfolio_id}/trader/${params.data.trader_id}/switch_on`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'portfolio_trader_switch_off') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/portfolio/${params.data.portfolio_id}/trader/${params.data.trader_id}/switch_off`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'trader_bench') {
                return Promise.resolve(
                    httpClient(`${apiUrl}/trader_bench`, {
                        method: 'POST',
                        body: JSON.stringify(params.data),
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'saxo_tokens/reset') {
                const token_id = params.data.id;
                return Promise.resolve(
                    httpClient(`/token_store/${token_id}/reset`, {
                        method: 'POST',
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'slot_orders') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/slots/${params.data.slot_id}/orders`,
                        {
                            method: 'POST',
                            body: JSON.stringify(params.data),
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'slot_start') {
                return Promise.resolve(
                    httpClient(`${apiUrl}/slots/${params.data.id}/start`, {
                        method: 'POST',
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'slot_stop') {
                return Promise.resolve(
                    httpClient(`${apiUrl}/slots/${params.data.id}/stop`, {
                        method: 'POST',
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'pl_emergency_brake/activate') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/pl_emergency_brake/${params.data.id}/activate`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'pl_emergency_brake/deactivate') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/pl_emergency_brake/${params.data.id}/deactivate`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_portfolio_launch_start') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_portfolio_launch/${params.data.id}/start`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_portfolio_launch_stop') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_portfolio_launch/${params.data.id}/stop`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_trader/start') {
                const rapid_trader_id = params.data.id;
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_trader/${rapid_trader_id}/start`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_trader/stop') {
                const rapid_trader_id = params.data.id;
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_trader/${rapid_trader_id}/stop`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_portfolio_launch_schedule/copy') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_portfolio_launch_schedule/copy`,
                        {
                            method: 'POST',
                            body: JSON.stringify(params.data),
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'history_fetcher_tasks_metrics') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/history_fetcher_tasks/${params.data.id}/metrics`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'history_fetcher_task_reset') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/history_fetcher_tasks/${params.data.id}/reset`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'history_fetcher_task_exclude') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/history_fetcher_tasks/${params.data.id}/exclude`,
                        { method: 'POST' }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'history_fetcher_task_reset_all_failed') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/history_fetcher_tasks/reset_all_failed`,
                        { method: 'POST', body: JSON.stringify(params.data) }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'portfolio_from_csv') {
                const formData = new FormData();
                if (params.meta.files === undefined) {
                    return Promise.reject(new Error('Выберите файл!'));
                }
                formData.append('traders', params.meta.files.rawFile);
                Object.keys(params.data).forEach(key => {
                    formData.append(key, params.data[key]);
                });
                return Promise.resolve(
                    httpClient(`${apiUrl}/portfolio/load`, {
                        method: 'POST',
                        body: formData,
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_portfolio_from_json') {
                const formData = new FormData();
                if (params.meta.files === undefined) {
                    return Promise.reject(new Error('Выберите файл!'));
                }
                formData.append('portfolio_json', params.meta.files.rawFile);
                Object.keys(params.data).forEach(key => {
                    formData.append(key, params.data[key]);
                });
                return Promise.resolve(
                    httpClient(`${apiUrl}/rapid_portfolio/load`, {
                        method: 'POST',
                        body: formData,
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'calendar_events') {
                const {
                    data: { instrument_group_codes, ...restData },
                    ...rest
                } = params;
                return Promise.resolve(
                    httpClient(`${apiUrl}/calendar_events`, {
                        method: 'POST',
                        body: JSON.stringify(restData, rest),
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'subaccounts/stop') {
                const subaccounts_id = params.data.id;
                return Promise.resolve(
                    httpClient(`${apiUrl}/subaccounts/${subaccounts_id}/stop`, {
                        method: 'POST',
                    })
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'subaccounts/start') {
                const subaccounts_id = params.data.id;
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/subaccounts/${subaccounts_id}/start`,
                        {
                            method: 'POST',
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else {
                return basicDataProvider.create(resource, params);
            }
        },
        getRunConfig: (resource, params) => {
            const rapid_portfolio_launch_id = params.id;
            return Promise.resolve(
                httpClient(
                    `${apiUrl}/rapid_portfolio_launch/${rapid_portfolio_launch_id}/run_config`,
                    {
                        method: 'GET',
                    }
                )
            ).then(response => {
                return {
                    data: response.json,
                };
            });
        },
        getRapidPortfolioLaunchConfig: (resource, params) => {
            const rapid_portfolio_launch_id = params.id;
            return Promise.resolve(
                httpClient(
                    `${apiUrl}/rapid_portfolio_launch/${rapid_portfolio_launch_id}/get_config`,
                    {
                        method: 'GET',
                    }
                )
            ).then(response => {
                return response.json.config_json;
            });
        },
        getOne: (resource, params) => {
            if (resource == 'rapid_portfolio_config') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_portfolio_launch/${params.id}/get_config`,
                        {
                            method: 'GET',
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else if (resource == 'rapid_trader_config') {
                return Promise.resolve(
                    httpClient(
                        `${apiUrl}/rapid_trader/${params.id}/get_config`,
                        {
                            method: 'GET',
                        }
                    )
                ).then(response => {
                    return {
                        data: response.json,
                    };
                });
            } else {
                return basicDataProvider.getOne(resource, params);
            }
        },
        getMany: (resource, params) => {
            if (resource == 'rapid_portfolio_config') {
                return Promise.all(
                    params.ids.map(id =>
                        httpClient(
                            `${apiUrl}/rapid_portfolio_launch/${id}/get_config`,
                            {
                                method: 'GET',
                            }
                        )
                    )
                ).then(responses => ({
                    data: responses.map(response => response.json),
                }));
            } else if (resource == 'rapid_trader_config') {
                return Promise.all(
                    params.ids.map(id =>
                        httpClient(`${apiUrl}/rapid_trader/${id}/get_config`, {
                            method: 'GET',
                        })
                    )
                ).then(responses => ({
                    data: responses.map(response => response.json),
                }));
            } else {
                return basicDataProvider.getMany(resource, params);
            }
        },
        getManyReference: (resource, params) => {
            if (resource == 'slot_orders') {
                //Special root for orders from slot
                return Promise.resolve(
                    httpClient(`${apiUrl}/slots/${params.id}/orders`, {
                        method: 'GET',
                        body: JSON.stringify(params.data),
                    })
                ).then(response => {
                    return {
                        data: response.json,
                        total: response.json.length,
                    };
                });
            } else {
                return basicDataProvider.getManyReference(resource, params);
            }
        },
        updateMany: (resource, params) => {
            return basicDataProvider.updateMany(resource, params);
        },
    };
};
