import React, { FC } from 'react';
import { DatesSetArg } from '@fullcalendar/react';
import {
    setHours,
    setMinutes,
    setSeconds,
    setMilliseconds,
    subMonths,
    addMonths,
} from 'date-fns';
import { CompleteCalendar } from '@react-admin/ra-calendar';
import {
    AutocompleteInput,
    ReferenceInput,
    required,
    SimpleForm,
    DateTimeInput,
    TopToolbar,
    CreateButton,
    SaveButton,
    DeleteButton,
} from 'react-admin';
import { useTheme } from '@mui/material';
import { CopyRapidPortfolioScheduleButton } from './Buttons';

export const dateToIsoFormat = (date: Date): string => {
    const result = date.toISOString();
    return result.slice(0, -1);
};

const customGetFilterValues = (
    dateInfo?: DatesSetArg,
    filterValues: any = {}
): any => {
    let now = new Date();
    now = setHours(now, 0);
    now = setMinutes(now, 0);
    now = setSeconds(now, 0);
    now = setMilliseconds(now, 0);
    const nowMinus1Month = subMonths(now, 1);
    const nowPlus2Months = addMonths(now, 2);
    return !dateInfo ||
        (dateInfo.start > nowMinus1Month && dateInfo.end < nowPlus2Months)
        ? {
              ...filterValues,
              start_gte: dateToIsoFormat(nowMinus1Month),
              start_lte: dateToIsoFormat(nowPlus2Months),
          }
        : {
              ...filterValues,
              start_gte: dateToIsoFormat(dateInfo.start),
              start_lte: dateToIsoFormat(dateInfo.end),
          };
};

const RapidPortfolioLaunchScheduleListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
            <CopyRapidPortfolioScheduleButton />
        </TopToolbar>
    </>
);

const PostEditToolbar = () => {
    return (
        <TopToolbar>
            <SaveButton />
            <DeleteButton mutationMode="pessimistic" />
        </TopToolbar>
    );
};

export const RapidPortfolioLaunchScheduleList = () => {
    const theme = useTheme();
    const colorForStatus = {
        pending: theme.palette.grey['500'],
        in_progress: theme.palette.primary.light,
        done: theme.palette.primary.dark,
        error: theme.palette.error.main,
    };
    return (
        <CompleteCalendar
            ListProps={{
                perPage: 10000,
                title: 'Rapid Portfolio Launch Schedule',
                actions: <RapidPortfolioLaunchScheduleListActions />,
                filterDefaultValues: customGetFilterValues(),
                children: <></>,
            }}
            CalendarProps={{
                getFilterValueFromInterval: (dateInfo, filterValues) =>
                    customGetFilterValues(dateInfo, filterValues),
                nowIndicator: true,
                convertToEvent: event => ({
                    id: String(event.id),
                    title: event.title,
                    start: event.start,
                    end: event.end,
                    backgroundColor: colorForStatus[event.status],
                    borderColor: colorForStatus[event.status],
                    editable: true,
                }),
            }}
        >
            <SimpleForm toolbar={<PostEditToolbar />}>
                <ReferenceInput
                    label="Rapid Portfolio Launch"
                    source="rapid_portfolio_launch_id"
                    reference="rapid_portfolio_launch"
                >
                    <AutocompleteInput
                        filterToQuery={searchText => ({
                            code__like: searchText,
                        })}
                        optionText="code"
                        optionValue="id"
                        validate={required()}
                    />
                </ReferenceInput>
                <DateTimeInput source="start" validate={required()} />
                <DateTimeInput source="end" validate={required()} />
            </SimpleForm>
        </CompleteCalendar>
    );
};
