import React, { useState } from 'react';
import {
    Button,
    useNotify,
    useCreate,
    useRefresh,
    useRecordContext,
    Confirm,
} from 'react-admin';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import StopIcon from '@mui/icons-material/Stop';

export const ActivateEmergencyBrakeButton = () => {
    const record = useRecordContext();
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const emergency_brake_id = record.id;
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'pl_emergency_brake/activate',
            {
                data: { id: emergency_brake_id },
            },
            {
                onError: error => {
                    notify(`Brake activation error: ${error.message}`, {
                        type: 'error',
                    });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Emergency Brake is activated.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<PlayArrowIcon />}
                label="Activate"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Activate emergency brake`}
                content={`Are you sure want to run emergency brake?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const DeactivateEmergencyBrakeButton = () => {
    const record = useRecordContext();
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const pl_emergency_brake = record.id;
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'pl_emergency_brake/deactivate',
            {
                data: { id: pl_emergency_brake },
            },
            {
                onError: error => {
                    notify(`Brake deactivation error: ${error.message}`, {
                        type: 'error',
                    });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Emergency Brake is deactivated!');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<StopIcon />}
                label="Deactivate"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Deactivate emergency brake.`}
                content={`Do you want to deactivate emergency brake?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};
