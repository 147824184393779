import React from 'react';
import { Edit, required, SimpleForm, TextInput } from 'react-admin';
import { validateNoSpacesAnyAmount } from '../../common/validators';

export const InstrumentGroupEdit = () => (
    <Edit title="Create an Instrument Group" mutationMode="pessimistic">
        <SimpleForm>
            <TextInput
                source="code"
                validate={[validateNoSpacesAnyAmount, required()]}
            />
        </SimpleForm>
    </Edit>
);
