import React, { FC } from 'react';
import {
    BooleanInput,
    BooleanField,
    SelectInput,
    TextField,
    TopToolbar,
    TextInput,
    CreateButton,
    regex,
    required,
    List,
    BulkDeleteButton,
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const validateNoSpaces = regex(
    /^[a-zA-Z0-9]+(?:[\s\.]+[a-zA-Z0-9]+)*$/,
    'Remove spaces'
);

const listFilters = [
    <TextInput key="instrument" label="Instrument" source="q" alwaysOn />,
];

const IBInstrumentForm: FC = () => (
    <RowForm>
        <TextField source="id" />
        {/* eslint-disable-next-line */}
        <TextInput
            source="name"
            validate={[validateNoSpaces, required()]}
        />
        <SelectInput
            source="price_type"
            choices={[{ id: 'trades', name: 'Trades' }]}
            defaultValue={'trades'}
        />
        <BooleanInput source="is_active" defaultValue={false} />
    </RowForm>
);

const IBInstrumentListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

export const IBInstrumentList = () => {
    return (
        <List
            filters={listFilters}
            actions={<IBInstrumentListActions />}
            hasCreate
            empty={false}
            perPage={25}
        >
            <EditableDatagrid
                rowClick="edit"
                createForm={<IBInstrumentForm />}
                editForm={<IBInstrumentForm />}
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
                mutationMode="pessimistic"
            >
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="price_type" />
                <BooleanField source="is_active" />
            </EditableDatagrid>
        </List>
    );
};
