import { SaxoInstrumentList } from './SaxoInstrumentList';
import { SaxoInstrumentInfoList } from './instrument_info/SaxoInstrumentInfoList';
import { SaxoInstrumentInfoShow } from './instrument_info/SaxoInstrumentInfoShow';
import { SaxoDonorMonitorList } from './SaxoDonorMonitor';
import { SaxoTradingTimesList } from './SaxoTradingTimes';

import InstrumentsIcon from '@mui/icons-material/PlayArrow';
import InstrumentInfoIcon from '@mui/icons-material/LibraryBooks';
import DonorMonitorIcon from '@mui/icons-material/Visibility';
import TradingTimesIcon from '@mui/icons-material/AccessTime';

export const saxoInstruments = {
    icon: InstrumentsIcon,
    list: SaxoInstrumentList,
};

export const saxoInstrumentInfo = {
    icon: InstrumentInfoIcon,
    list: SaxoInstrumentInfoList,
    show: SaxoInstrumentInfoShow,
};

export const saxoDonorMonitor = {
    icon: DonorMonitorIcon,
    list: SaxoDonorMonitorList,
};

export const saxoTradingTimes = {
    icon: TradingTimesIcon,
    list: SaxoTradingTimesList,
};
