import React, { FC } from 'react';
import {
    List,
    TextField,
    BooleanField,
    NumberField,
    TextInput,
    NumberInput,
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

import StopCircleIcon from '@mui/icons-material/StopCircle';
import {
    ActivateEmergencyBrakeButton,
    DeactivateEmergencyBrakeButton,
} from './Buttons';
export const PLEmergencyBrakeIcon = StopCircleIcon;

const PLEmergencyBrakeListForm: FC = () => (
    <RowForm>
        <TextField source="id" />
        <BooleanField
            source="active"
            valueLabelTrue="Active"
            valueLabelFalse="Inactive"
        />
        <TextInput source="description" label="Description" />
        <NumberInput
            source="absolute_positive_pnl_stop"
            label="Positive stop"
        />
        <NumberInput
            source="absolute_negative_pnl_stop"
            label="Negative stop"
        />
    </RowForm>
);

export const PLEmergencyBrakeList = () => {
    return (
        <List actions={false} empty={false} perPage={25}>
            <EditableDatagrid
                bulkActionButtons={false}
                data-testid="pl-emergency-brake-datagrid"
                editForm={<PLEmergencyBrakeListForm />}
                noDelete
                mutationMode="pessimistic"
            >
                <TextField source="id" />
                <BooleanField
                    source="active"
                    valueLabelTrue="Active"
                    valueLabelFalse="Inactive"
                />
                <TextField source="description" label="Description" />
                <NumberField
                    source="absolute_positive_pnl_stop"
                    label="Positive stop"
                />
                <NumberField
                    source="absolute_negative_pnl_stop"
                    label="Negative stop"
                />
                <ActivateEmergencyBrakeButton />
                <DeactivateEmergencyBrakeButton />
            </EditableDatagrid>
        </List>
    );
};
