import InstrumentMappingIcon from '@mui/icons-material/SyncAlt';
import { InstrumentMappingList } from './InstrumentMappingList';
import { InstrumentMappingEdit } from './InstrumentMappingEdit';
import { InstrumentMappingCreate } from './InstrumentMappingCreate';

export default {
    icon: InstrumentMappingIcon,
    list: InstrumentMappingList,
    create: InstrumentMappingCreate,
    edit: InstrumentMappingEdit,
};
