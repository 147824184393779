import React, { useState } from 'react';
import {
    Button,
    DateInput,
    required,
    useNotify,
    SaveButton,
    useCreate,
    useRefresh,
    Form,
    SaveContextProvider,
} from 'react-admin';
import Dialog from '@mui/material/Dialog';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconCancel from '@mui/icons-material/Cancel';

export const CopyRapidPortfolioScheduleButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };
    const saving = false;
    const mutationMode = 'pessimistic';
    const save = values => {
        console.log(values);
        create(
            'rapid_portfolio_launch_schedule/copy',
            { data: values },
            {
                onError: err => {
                    // @ts-ignore
                    for (const request_error of err.body.errors) {
                        notify(request_error['detail'], { type: 'error' });
                    }
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Success!.');
                },
            }
        );
    };
    return (
        <>
            <Button
                variant="contained"
                startIcon={<PlayArrowIcon />}
                label="Copy rapid portfolio schedule records"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Confirm Loading"
            >
                <DialogTitle> Portfolio creation</DialogTitle>
                <SaveContextProvider value={{ save, saving, mutationMode }}>
                    <Form>
                        <DialogContent>
                            <DateInput
                                source="copy_from_date"
                                validate={required()}
                                inputProps={{
                                    min: '1000-01-01',
                                    max: '9999-01-01',
                                }}
                            />
                            <DateInput
                                source="copy_to_date"
                                validate={required()}
                                inputProps={{
                                    min: '1000-01-01',
                                    max: '9999-01-01',
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button
                                label="ra.action.cancel"
                                onClick={handleCloseClick}
                                disabled={isLoading}
                            >
                                <IconCancel />
                            </Button>
                            <SaveButton
                                label={'Copy Schedule'}
                                disabled={isLoading}
                            />
                        </DialogActions>
                    </Form>
                </SaveContextProvider>
            </Dialog>
        </>
    );
};
