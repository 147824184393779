import React from 'react';
import {
    Create,
    required,
    SimpleForm,
    ReferenceInput,
    AutocompleteInput,
} from 'react-admin';
import { JsonInput } from '@bb-tech/ra-components';

export const MarketDataScheduleCreate = () => (
    <Create title="Create an Market Data Schedule" redirect="list">
        <SimpleForm>
            <ReferenceInput
                label="Instrument Group"
                source="instrument_group_id"
                reference="instrument_group"
                validate={required()}
            >
                <AutocompleteInput
                    filterToQuery={searchText => ({
                        code__icontains: searchText,
                    })}
                    optionText="code"
                    validate={required()}
                />
            </ReferenceInput>
            <JsonInput
                source="market_data_schedule"
                label="Market Data Schedule"
                fullWidth
                validate={[required()]}
            />
        </SimpleForm>
    </Create>
);
