import React from 'react';
import {
    TextField,
    ReferenceField,
    List,
    Datagrid,
    BooleanField,
    EditButton,
    ReferenceInput,
    AutocompleteInput,
    TextInput,
    NullableBooleanInput,
    SelectInput,
} from 'react-admin';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';

const listFilters = [
    <ReferenceInput
        key="rapid_portfolio_launch"
        source="rapid_portfolio_launch_id"
        reference="rapid_portfolio_launch"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ code__like: searchText })}
            optionText="code"
            optionValue="id"
            sx={{ width: '250px' }}
        />
    </ReferenceInput>,
    <TextInput
        key="instrument_group__icontains"
        label="Instrument group"
        source="instrument_group__icontains"
        alwaysOn
    />,
    <NullableBooleanInput
        key="dry_mode"
        label="Dry mode"
        source="dry_mode"
        alwaysOn
    />,
    <SelectInput
        key="rapid_portfolio_launch_status"
        label="Status"
        source="status"
        choices={[
            { id: 'stopped', name: 'Stopped' },
            { id: 'pending', name: 'Pending' },
            { id: 'running', name: 'Running' },
            { id: 'stopping', name: 'Stopping' },
            { id: 'error', name: 'Error' },
        ]}
        alwaysOn
    />,
];

export const RapidTraderList = () => {
    return (
        <List
            title="Rapid Traders"
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            filters={listFilters}
            actions={false}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <ReferenceField
                    source="rapid_portfolio_launch_id"
                    label="Rapid Portfolio Launch Code"
                    reference="rapid_portfolio_launch"
                    link="show"
                    sortable={false}
                >
                    <TextField source="code" />
                </ReferenceField>
                <TextField source="instrument_group" />
                <ReferenceField
                    label="Actual contract"
                    source="actual_contract_id"
                    reference="instrument_mapping"
                    link={false}
                    sortable={false}
                >
                    <TextField source="instrument_code" />
                </ReferenceField>
                <ReferenceField
                    label="Rapid Portfolio Status"
                    source="rapid_portfolio_launch_id"
                    reference="rapid_portfolio_launch"
                    link={false}
                    sortable={false}
                >
                    <TextField source="status" />
                </ReferenceField>
                <BooleanField source="dry_mode" />
                <TextField source="tactic_mode" />
                <EditButton />
            </Datagrid>
        </List>
    );
};
