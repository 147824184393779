import * as React from 'react';
import {
    TextField,
    DateField,
    TextInput,
    SelectInput,
    Datagrid,
    NumberField,
    EditButton,
    List,
    BulkDeleteButton,
} from 'react-admin';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const listFilters = [
    <TextInput key="like_name" label="Name" source="like_name" alwaysOn />,
    <SelectInput
        key="broker"
        label="Broker"
        source="broker"
        choices={[
            { id: 'exante', name: 'Exante' },
            { id: 'saxo', name: 'Saxo' },
            { id: 'interactive_brokers', name: 'IB' },
            { id: 'trading_view', name: 'TV' },
            { id: 'refinitiv', name: 'Refinitiv' },
            { id: 'refinitiv_seconds', name: 'Refinitiv Seconds' },
            { id: 'finam', name: 'Finam' },
            { id: 'binance', name: 'Binance' },
            { id: 'moex', name: 'MOEX' },
            { id: 'cme', name: 'CME' },
            { id: 'cbrf', name: 'CBRF' },
        ]}
        alwaysOn
    />,
    <TextInput
        key="asset_type"
        label="Asset type"
        source="asset_type"
        alwaysOn
    />,
    <SelectInput
        key="tick_type"
        label="Tick type"
        source="tick_type"
        choices={[
            { id: 'trades', name: 'Trades' },
            { id: 'quotes', name: 'Quotes' },
        ]}
        alwaysOn
    />,
    <SelectInput
        key="status"
        label="Status"
        source="status"
        choices={[
            { id: 'running', name: 'Running' },
            { id: 'paused', name: 'Paused' },
            { id: 'failed', name: 'Failed' },
            { id: 'done', name: 'Done' },
        ]}
        alwaysOn
    />,
];

export const HistoryFetcherInstrumentList = () => (
    <List
        filters={listFilters}
        sort={{ field: 'priority', order: 'DESC' }}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
        hasCreate
    >
        <Datagrid
            bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
        >
            <TextField source="id" />
            <TextField source="name" />
            <NumberField source="priority" />
            <TextField source="broker" />
            <TextField source="status" />
            <TextField source="asset_type" />
            <TextField source="exchange" />
            <TextField source="tick_type" />
            <DateField label="Start Time" source="load_from" showTime />
            <DateField label="End Time" source="load_to" showTime />
            <EditButton />
        </Datagrid>
    </List>
);
