import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useNotify,
    useRefresh,
    useCreate,
    useRecordContext,
} from 'react-admin';
import RestartIcon from '@mui/icons-material/Autorenew';
import NetworkCheckIcon from '@mui/icons-material/NetworkCheck';
import ArchiveIcon from '@mui/icons-material/Archive';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

export const MetricsButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();
    const record = useRecordContext();
    const task = { id: record.id };
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'history_fetcher_tasks_metrics',
            { data: task },
            {
                onError: () => {
                    notify('Metrics calculation: error', { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Metrics calculation scheduled.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<NetworkCheckIcon />}
                label="Metrics"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Metrics for ${record.id} task`}
                content={`Calculate metrics for task ${record.id}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const ResetTaskButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();
    const record = useRecordContext();
    const task = { id: record.id };
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'history_fetcher_task_reset',
            { data: task },
            {
                onError: () => {
                    notify('Task reset: error', { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Task reseted.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<RestartIcon />}
                label="Reset"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Reset ${record.id} task`}
                content={`Do you want to reset task ${record.id}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const ExcludeTaskButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();
    const record = useRecordContext();
    const task = { id: record.id };
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'history_fetcher_task_exclude',
            { data: task },
            {
                onError: () => {
                    notify('Task exclude: error', { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Task excluded.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<ArchiveIcon />}
                label="Exclude"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Exclude ${record.id} task`}
                content={`Do you want to exclude task ${record.id}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const ResetAllFailedTasksButton = filterValues => {
    const filterValuesData = filterValues.filterValues;
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'history_fetcher_task_reset_all_failed',
            { data: { instrument_broker: filterValuesData.instrument_broker } },
            {
                onError: () => {
                    setShowDialog(false);
                    notify('Tasks reset: error', { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Tasks reseted.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<RestartIcon />}
                label="Reset all failed"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Reset all failed tasks`}
                content={`Do you want to reset all failed tasks?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};
