import React, { FC } from 'react';
import {
    Show,
    TextField,
    Datagrid,
    TabbedShowLayout,
    Tab,
    Pagination,
    ReferenceManyField,
    BooleanField,
    DateField,
    ReferenceField,
    BooleanInput,
    SelectInput,
    required,
    AutocompleteInput,
    ReferenceInput,
    useEditContext,
    ShowButton,
} from 'react-admin';

import { RunConfigShow, ConfigShow } from './Buttons';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import ToggleOnIcon from '@mui/icons-material/ToggleOnTwoTone';
import ToggleOffIcon from '@mui/icons-material/ToggleOffTwoTone';

export const RapidPortfolioLaunchShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="description" />
                </Tab>
                <Tab label="Rapid Traders">
                    <ReferenceManyField
                        label="Rapid Traders"
                        reference="rapid_trader"
                        target="rapid_portfolio_launch_id"
                        pagination={<Pagination />}
                    >
                        <RapidTraderDataGrid />
                    </ReferenceManyField>
                </Tab>
                <Tab label="Config">
                    <ConfigShow />
                </Tab>
                <Tab label="Run Config">
                    <RunConfigShow />
                </Tab>
                <Tab label="Journal">
                    <ReferenceManyField
                        reference="rapid_portfolio_launch_pod_events"
                        target="rapid_portfolio_launch_id"
                        label={false}
                        pagination={<Pagination />}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="id" />
                            <TextField label="Event type" source="event.type" />
                            <DateField showTime source="event.created_at" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const FilterButton = () => {
    const { record } = useEditContext();
    return (
        <ReferenceInput
            label="Actual contract"
            source="actual_contract_id"
            reference="instrument_mapping"
            filter={{
                instrument_group: record.instrument_group,
                exante_switch_date_after: new Date(
                    new Date().setHours(0, 0, 0, 0)
                ),
            }}
            perPage={300}
        >
            <AutocompleteInput
                filterToQuery={searchText => ({
                    instrument_code: searchText,
                })}
                optionText="instrument_code"
                optionValue="id"
                sx={{ width: '250px' }}
            />
        </ReferenceInput>
    );
};

const RapidTraderRowEdit: FC = () => (
    <RowForm>
        <TextField source="id" />
        <TextField source="instrument_group" />
        <FilterButton />
        <ReferenceField
            label="Rapid Portfolio Status"
            source="rapid_portfolio_launch_id"
            reference="rapid_portfolio_launch"
            link={false}
            sortable={false}
        >
            <TextField source="status" />
        </ReferenceField>
        <BooleanInput source="dry_mode" />
        <SelectInput
            label="Tactic mode"
            source="tactic_mode"
            choices={[
                { id: 'auto', name: 'Auto' },
                { id: 'profit', name: 'Profit' },
                { id: 'stop', name: 'Stop' },
                { id: 'main', name: 'Main' },
            ]}
            validate={required()}
        />
    </RowForm>
);

const TrueIcon = () => {
    return <ToggleOnIcon color="success" fontSize="large" />;
};

const FalseIcon = () => {
    return <ToggleOffIcon color="error" fontSize="large" />;
};

const RapidTraderDataGrid = props => {
    return (
        <EditableDatagrid
            bulkActionButtons={false}
            rowClick="edit"
            data-testid="RapidTraderRowEditId"
            editForm={<RapidTraderRowEdit />}
            noDelete
            mutationMode="pessimistic"
        >
            <TextField source="id" />
            <TextField source="instrument_group" />
            <ReferenceField
                label="Actual contract"
                source="actual_contract_id"
                reference="instrument_mapping"
                link={false}
                sortable={false}
            >
                <TextField source="instrument_code" />
            </ReferenceField>
            <ReferenceField
                label="Rapid Portfolio Status"
                source="rapid_portfolio_launch_id"
                reference="rapid_portfolio_launch"
                link={false}
                sortable={false}
            >
                <TextField source="status" />
            </ReferenceField>
            <BooleanField
                source="dry_mode"
                TrueIcon={TrueIcon}
                FalseIcon={FalseIcon}
                {...props}
            />
            <TextField source="tactic_mode" />
            <ShowButton />
        </EditableDatagrid>
    );
};
