import React, { FC } from 'react';
import {
    TextField,
    CreateButton,
    TopToolbar,
    ReferenceField,
    List,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    DateField,
    BulkDeleteButton,
    Datagrid,
    BooleanField,
    NullableBooleanInput,
    ShowButton,
    EditButton,
    SelectInput,
    NumberInput,
} from 'react-admin';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import {
    StartRapidPortfolioLaunchButton,
    StopRapidPortfolioLaunchButton,
} from './Buttons';

const RapidPortfolioLaunchListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

const listFilters = [
    <NumberInput
        key="portfolio_launch_id"
        label="ID"
        source="id"
        alwaysOn
        sx={{ width: '100px' }}
    />,
    <TextInput
        key="code__like"
        label="Portfolio launch code"
        source="code__like"
        alwaysOn
    />,
    <ReferenceInput
        key="portfolio"
        label="Portfolio"
        source="rapid_portfolio_id"
        reference="rapid_portfolio"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ code__like: searchText })}
            optionText="code"
            optionValue="id"
        />
    </ReferenceInput>,
    <ReferenceInput
        key="subaccount"
        label="Subaccount"
        source="subaccount_id"
        reference="subaccounts"
        perPage={300}
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ code__icontains: searchText })}
            optionText="code"
            optionValue="id"
            sx={{ width: '200px' }}
        />
    </ReferenceInput>,
    <ReferenceInput
        key="subaccount_alias"
        source="subaccount_id"
        reference="subaccounts"
        perPage={300}
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ alias__icontains: searchText })}
            optionText="alias"
            optionValue="id"
            sx={{ width: '200px' }}
            label="Alias"
        />
    </ReferenceInput>,
    <NullableBooleanInput
        key="is_live"
        label="Is live"
        source="is_live"
        alwaysOn
    />,
    <NullableBooleanInput
        key="is_active"
        label="Is active"
        source="is_active"
        alwaysOn
    />,
    <SelectInput
        key="rapid_portfolio_launch_status"
        label="Status"
        source="status"
        choices={[
            { id: 'stopped', name: 'Stopped' },
            { id: 'pending', name: 'Pending' },
            { id: 'running', name: 'Running' },
            { id: 'stopping', name: 'Stopping' },
            { id: 'error', name: 'Error' },
        ]}
        alwaysOn
    />,
];

export const RapidPortfolioLaunchList = () => {
    return (
        <List
            title="Portfolio Launches"
            filters={listFilters}
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<RapidPortfolioLaunchListActions />}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField source="id" />
                <TextField source="code" />
                <ReferenceField
                    label="Rapid Portfolio Code"
                    source="rapid_portfolio_id"
                    reference="rapid_portfolio"
                    link="show"
                    sortable={false}
                >
                    <TextField source="code" />
                </ReferenceField>
                <ReferenceField
                    label="Subaccount"
                    source="subaccount_id"
                    reference="subaccounts"
                    link="show"
                    sortable={false}
                >
                    <TextField source="code" />
                </ReferenceField>
                <ReferenceField
                    label="Subaccount Alias"
                    source="subaccount_id"
                    reference="subaccounts"
                    link="show"
                    sortable={false}
                >
                    <TextField source="alias" />
                </ReferenceField>
                <DateField source="created_at" />
                <BooleanField source="is_live" />
                <BooleanField source="is_active" />
                <TextField source="status" />
                <ReferenceField
                    label="Docker tag"
                    source="docker_tag_id"
                    reference="docker_tags"
                    sortable={false}
                >
                    <TextField source="description" />
                </ReferenceField>
                <ShowButton />
                <StartRapidPortfolioLaunchButton />
                <StopRapidPortfolioLaunchButton />
                <EditButton />
            </Datagrid>
        </List>
    );
};
