import React, { FC } from 'react';
import {
    TextField,
    Datagrid,
    TopToolbar,
    List,
    DateField,
    BulkDeleteButton,
    ShowButton,
    TextInput,
} from 'react-admin';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import { CreatePortfolioFromJSON } from './Buttons';

const RapidPortfolioListActions: FC = () => (
    <>
        <TopToolbar>
            <CreatePortfolioFromJSON />
        </TopToolbar>
    </>
);

const listFilters = [
    <TextInput
        key="code__like"
        label="Portfolio code"
        source="code__like"
        alwaysOn
    />,
];

export const RapidPortfolioList = () => {
    return (
        <List
            title="Portfolios"
            sort={{ field: 'id', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<RapidPortfolioListActions />}
            empty={false}
            hasCreate={false}
            filters={listFilters}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField source="id" />
                <TextField source="code" />
                <TextField source="description" sortable={false} />
                <DateField source="created_at" />
                <ShowButton />
            </Datagrid>
        </List>
    );
};
