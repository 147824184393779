import React from 'react';
import {
    ReferenceField,
    Datagrid,
    DateField,
    NumberField,
    TextField,
    List,
    ReferenceInput,
    AutocompleteInput,
    CreateButton,
    ExportButton,
    TopToolbar,
} from 'react-admin';

import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const listFilters = [
    <ReferenceInput
        key="subaccount"
        label="Subaccount"
        source="subaccount"
        reference="subaccounts"
        alwaysOn
    >
        <AutocompleteInput
            optionText="code"
            sx={{ width: '250px' }}
            filterToQuery={searchText => ({
                code__icontains: searchText,
            })}
        />
    </ReferenceInput>,
    <ReferenceInput
        key="subaccount_alias"
        label="Alias"
        source="subaccount"
        reference="subaccounts"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ alias__icontains: searchText })}
            optionText="alias"
            optionValue="id"
            sx={{ width: '200px' }}
        />
    </ReferenceInput>,
];

const ListActions = () => (
    <TopToolbar>
        <CreateButton />
        <ExportButton />
    </TopToolbar>
);

export const FundMovementIcon = CurrencyExchangeIcon;

export const FundMovementList = () => {
    return (
        <List
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            sort={{ field: 'moved_at', order: 'DESC' }}
            filters={listFilters}
            actions={<ListActions />}
        >
            <Datagrid bulkActionButtons={false}>
                <ReferenceField
                    label="Subaccount"
                    source="subaccount"
                    reference="subaccounts"
                    resource="subaccounts"
                >
                    <TextField source="code" />
                </ReferenceField>
                <ReferenceField
                    label="Alias"
                    source="subaccount"
                    reference="subaccounts"
                    resource="subaccounts"
                >
                    <TextField source="alias" />
                </ReferenceField>
                <NumberField
                    label="Transaction value"
                    source="fund_move"
                    sortable={false}
                />
                <DateField
                    label="Transaction timestamp"
                    source="moved_at"
                    showTime={true}
                />
            </Datagrid>
        </List>
    );
};
