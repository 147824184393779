import React, { useState } from 'react';
import { Datagrid, NumberField, TextField, List } from 'react-admin';
import './SubaccountSnapshotReportDownload.css';
import { httpClientWithAuth } from '../auth_provider/authHandlers';

const preview_limit = 50;

const today = new Date();
today.setHours(0, 0, 0, 0);

const nextDay = new Date();
nextDay.setDate(nextDay.getDate() + 1);
nextDay.setHours(0, 0, 0, 0);

export const SubaccountSnapshotReportList = () => {
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(nextDay);
    const [subaccount, setSubaccount] = useState<string | null>(null);

    const validateAndSetToDate = newToDate => {
        if (newToDate > fromDate) {
            setToDate(newToDate);
        } else {
            alert('To date cannot be before from date.');
        }
    };

    const validateAndSetFromDate = newFromDate => {
        if (newFromDate < toDate) {
            setFromDate(newFromDate);
        } else {
            alert('From date cannot be after to date.');
        }
    };

    const downloadSubaccountsSnapshotCsv = async () => {
        if (fromDate > toDate) {
            alert('From date cannot be after to date.');
            return;
        }
        let url = `/api/subaccount_snapshot?from_date=${fromDate.toISOString()}&to_date=${toDate.toISOString()}`;
        if (subaccount) {
            url += `&subaccount=${subaccount}`;
        }
        try {
            const options = {
                method: 'GET',
                headers: {
                    Accept: 'text/csv',
                },
            };
            const response = await httpClientWithAuth(url, options);

            console.log('got resp');
            if (response.status != 200) {
                alert('Failed to download report: ' + response.body);
                return;
            }
            const blob = new Blob([response.body], { type: 'text/csv' });
            const url_doc = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url_doc;
            a.download = 'subaccount_snapshot_report.csv';
            a.click();
        } catch (error) {
            alert('Failed to download report. Please try again.');
        }
    };

    return (
        <>
            <div className="date-range">
                Date range: from
                <input
                    className="date-input"
                    type="date"
                    value={fromDate.toISOString().split('T')[0]}
                    onChange={e =>
                        validateAndSetFromDate(new Date(e.target.value))
                    }
                />
                to:
                <input
                    className="date-input"
                    type="date"
                    value={toDate.toISOString().split('T')[0]}
                    onChange={e =>
                        validateAndSetToDate(new Date(e.target.value))
                    }
                />
            </div>
            <div className="subaccount-input">
                Subaccount:
                <input
                    type="text"
                    value={subaccount ?? ''}
                    onChange={e => setSubaccount(e.target.value)}
                />
            </div>
            <div>
                Для предпросмотра доступно только {preview_limit} записей.
            </div>
            <List
                title="Subaccount Snapshot Report"
                filter={{
                    from_date: fromDate,
                    to_date: toDate,
                    subaccount: subaccount,
                    limit: preview_limit,
                }}
                pagination={false}
                exporter={downloadSubaccountsSnapshotCsv}
                queryOptions={{ refetchInterval: 5 * 60 * 1000 }}
            >
                <Datagrid bulkActionButtons={false}>
                    <TextField source="id" />
                    <TextField source="subaccount" />
                    <NumberField source="margin_utilization" />
                    <NumberField source="free_money" />
                    <TextField source="timestamp" />
                </Datagrid>
            </List>
        </>
    );
};
