import * as React from 'react';
import {
    List,
    BooleanField,
    Datagrid,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import { JsonField } from 'react-admin-json-view';

const listFilters = [
    <TextInput
        key="instrument"
        label="Instrument"
        source="instrument"
        alwaysOn
    />,
];

export const FinamDonorMonitorList = () => (
    <List
        filters={listFilters}
        sort={{ field: 'is_active', order: 'DESC' }}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
    >
        <Datagrid
            rowClick="expand"
            expand={<FinamDonorMonitorShow />}
            bulkActionButtons={false}
        >
            <TextField label="Market Data UID" source="id" />
            <TextField source="instrument" />
            <TextField source="price_type" />
            <TextField source="status" />
            <BooleanField source="is_active" />
            <TextField source="error_msg" />
            <TextField label="Last Tick Time (UTC)" source="last_tick_time" />
            <TextField
                label="Last Volume Time (UTC)"
                source="last_volume_time"
            />
            <TextField label="Created at (UTC)" source="created_at" />
        </Datagrid>
    </List>
);

export const FinamDonorMonitorShow = () => {
    const record = useRecordContext();
    return (
        <div>
            <JsonField record={record} source="donor_element" />
        </div>
    );
};
