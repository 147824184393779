import React, { FC } from 'react';
import {
    Datagrid,
    TextField,
    List,
    TopToolbar,
    CreateButton,
    EditButton,
    DeleteButton,
    TextInput,
    BulkDeleteButton,
} from 'react-admin';

import WorkspacesIcon from '@mui/icons-material/Workspaces';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';

export const InstrumentGroupIcon = WorkspacesIcon;

const listFilters = [
    <TextInput
        key="instrument_group"
        label="Search"
        source="code__icontains"
        alwaysOn
    />,
];

const InstrumentGroupListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

export const InstrumentGroupList = () => {
    return (
        <List
            title="Instrument groups"
            sort={{ field: 'id', order: 'DESC' }}
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<InstrumentGroupListActions />}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField source="id" />
                <TextField source="code" label="Code" />
                <EditButton />
                <DeleteButton mutationMode="pessimistic" />
            </Datagrid>
        </List>
    );
};
