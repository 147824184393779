import React, { useState } from 'react';
import {
    Button,
    Confirm,
    useNotify,
    useRefresh,
    useCreate,
    useRecordContext,
    Labeled,
    useDataProvider,
    Loading,
    useUpdate,
    SimpleForm,
    SaveContextProvider,
    SaveButton,
    Toolbar,
} from 'react-admin';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import StopIcon from '@mui/icons-material/Stop';
import ReactJson from 'react-json-view';
import { useQuery } from 'react-query';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { JsonInput } from 'react-admin-json-view';
import IconCancel from '@mui/icons-material/Cancel';

const RapidPortfolioLaunchConfigEditToolbar = props => {
    return (
        <Toolbar sx={{ position: 'sticky', bottom: 0, zIndex: 2 }}>
            <SaveButton />
            <Button
                label="ra.action.cancel"
                onClick={props.onClick}
                disabled={props.disabled}
                sx={{
                    position: 'absolute',
                    right: 8,
                }}
                color="error"
            >
                <IconCancel />
            </Button>
        </Toolbar>
    );
};

export const StartRapidPortfolioLaunchButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const record = useRecordContext();
    const rapid_portfolio_launch_id = record.id;
    const rapid_portfolio_launch_code = record.code;
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'rapid_portfolio_launch_start',
            { data: { id: rapid_portfolio_launch_id } },
            {
                onError: error => {
                    notify(`Start error: ${error.message}`, { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Portfolio Launch started.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<PlayArrowIcon />}
                label="Start"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Start portfolio launch ${rapid_portfolio_launch_code}`}
                content={`Do you want to start portfolio launch ${rapid_portfolio_launch_code}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const StopRapidPortfolioLaunchButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const record = useRecordContext();
    const rapid_portfolio_launch_id = record.id;
    const rapid_portfolio_launch_code = record.code;
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'rapid_portfolio_launch_stop',
            { data: { id: rapid_portfolio_launch_id } },
            {
                onError: error => {
                    notify(`Stop error: ${error.message}`, { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Portfolio Launch stopped.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<StopIcon />}
                label="Stop"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Stop portfolio launch ${rapid_portfolio_launch_code}`}
                content={`Do you want to stop portfolio launch ${rapid_portfolio_launch_code}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const RunConfigShow = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const rapid_portfolio_launch_id = record.id;
    const {
        data: run_config,
        isLoading,
        error,
    } = useQuery(
        ['run_config', 'getRunConfig', { id: rapid_portfolio_launch_id }],
        () =>
            dataProvider.getRunConfig('run_config', {
                id: rapid_portfolio_launch_id,
            })
    );

    if (isLoading) return <Loading />;
    if (error) return <p>Error</p>;

    return (
        <div>
            <Labeled label="Run Config">
                <ReactJson src={run_config} />
            </Labeled>
        </div>
    );
};

export const ConfigShow = () => {
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const rapid_portfolio_launch_id = record.id;
    const {
        data: config,
        isLoading,
        error,
    } = useQuery(
        [
            'get_config',
            'getRapidPortfolioLaunchConfig',
            { id: rapid_portfolio_launch_id },
        ],
        () =>
            dataProvider.getRapidPortfolioLaunchConfig('get_config', {
                id: rapid_portfolio_launch_id,
            })
    );

    if (isLoading) return <Loading />;
    if (error) return <p>Error</p>;

    return (
        <div>
            <Labeled label="Config">
                <ReactJson src={config} />
            </Labeled>
        </div>
    );
};

export const RapidPortfolioConfigEdit = () => {
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate<any, Error>();
    const refresh = useRefresh();
    const record = useRecordContext();
    const saving = false;
    const mutationMode = 'pessimistic';
    const rapid_portfolio_launch_id = record.id;

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };
    const save = async values => {
        update(
            'rapid_portfolio_config',
            { id: rapid_portfolio_launch_id, data: values },
            {
                onError: error => {
                    notify(`Modify error: ${error.message}`, { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Config is updated.');
                },
            }
        );
    };
    return (
        <>
            <Button
                variant="text"
                label="Edit config"
                onClick={handleClick}
                disabled={isLoading}
            >
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Confirm Copy"
            >
                <DialogTitle>Create Trader Launch</DialogTitle>
                <SaveContextProvider value={{ save, saving, mutationMode }}>
                    <SimpleForm
                        toolbar={
                            <RapidPortfolioLaunchConfigEditToolbar
                                disabled={isLoading}
                                onClick={handleCloseClick}
                            />
                        }
                    >
                        <JsonInput source="config_json" />
                    </SimpleForm>
                </SaveContextProvider>
            </Dialog>
        </>
    );
};
