import React from 'react';
import {
    SimpleForm,
    SelectInput,
    required,
    Edit,
    BooleanInput,
    Toolbar,
    SaveButton,
    ReferenceField,
    ReferenceInput,
    AutocompleteInput,
    useEditContext,
} from 'react-admin';
import { RapidTraderConfigEdit } from './Buttons';

const RapidTraderEditToolbar = () => (
    <Toolbar>
        <SaveButton />
    </Toolbar>
);

const FilterButton = () => {
    const { record } = useEditContext();
    return (
        <ReferenceInput
            label="Actual contract"
            source="actual_contract_id"
            reference="instrument_mapping"
            filter={{
                instrument_group: record.instrument_group,
                exante_switch_date_after: new Date(
                    new Date().setHours(0, 0, 0, 0)
                ),
            }}
            perPage={300}
        >
            <AutocompleteInput
                filterToQuery={searchText => ({
                    instrument_code: searchText,
                })}
                optionText="instrument_code"
                optionValue="id"
                sx={{ width: '250px' }}
            />
        </ReferenceInput>
    );
};

export const RapidTraderEdit = () => {
    return (
        <Edit title="Edit rapid trader" mutationMode="pessimistic">
            <SimpleForm toolbar={<RapidTraderEditToolbar />}>
                <BooleanInput source="dry_mode" validate={required()} />
                <SelectInput
                    label="Tactic mode"
                    source="tactic_mode"
                    choices={[
                        { id: 'auto', name: 'Auto' },
                        { id: 'profit', name: 'Profit' },
                        { id: 'stop', name: 'Stop' },
                        { id: 'main', name: 'Main' },
                    ]}
                    validate={required()}
                />
                <FilterButton />
                <ReferenceField
                    label={false}
                    source="id"
                    reference="rapid_trader_config"
                    sortable={false}
                >
                    <RapidTraderConfigEdit />
                </ReferenceField>
            </SimpleForm>
        </Edit>
    );
};
