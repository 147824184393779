import React from 'react';
import { Edit, regex, required, SimpleForm, TextInput } from 'react-admin';

export const validateNonNegativeInteger = regex(
    /^\d+$/,
    'Invalid format. Only non-negative integers are allowed.'
);

export const DagsterQuoteEdit = () => (
    <Edit title="Change Quote for User" mutationMode="pessimistic">
        <SimpleForm>
            <TextInput
                source="quote"
                validate={[validateNonNegativeInteger, required()]}
            />
        </SimpleForm>
    </Edit>
);
