import { BinanceInstrumentList } from './BinanceInstrumentList';
import { BinanceInstrumentInfoList } from './instrument_info/BinanceInstrumentInfoList';
import { BinanceInstrumentInfoShow } from './instrument_info/BinanceInstrumentInfoShow';
import InstrumentsIcon from '@mui/icons-material/PlayArrow';
import InstrumentInfoIcon from '@mui/icons-material/LibraryBooks';
import { BinanceInstrumentInfoCreate } from './instrument_info/BinanceInstrumentInfoCreate';

export const binanceInstruments = {
    icon: InstrumentsIcon,
    list: BinanceInstrumentList,
};

export const binanceInstrumentInfo = {
    icon: InstrumentInfoIcon,
    list: BinanceInstrumentInfoList,
    show: BinanceInstrumentInfoShow,
    create: BinanceInstrumentInfoCreate,
};
