import * as React from 'react';
import { useContext } from 'react';
import { AppBar, Logout, ToggleThemeButton, UserMenu } from 'react-admin';
import { Box, Typography, useMediaQuery, Theme } from '@mui/material';
import { ThemesContext } from '@react-admin/ra-enterprise';

const CustomUserMenu = () => (
    <UserMenu>
        <Logout />
    </UserMenu>
);

const CustomAppBar = (props: any) => {
    const isMediumAndUp = useMediaQuery<Theme>(theme =>
        theme.breakpoints.up('md')
    );
    const { darkTheme, lightTheme } = useContext(ThemesContext);

    return (
        <AppBar
            {...props}
            color="secondary"
            elevation={1}
            userMenu={<CustomUserMenu />}
        >
            <Typography
                variant="h6"
                color="inherit"
                sx={{
                    flex: 1,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                }}
                id="react-admin-title"
            />
            <Box component="span" sx={{ flex: 1 }} />
            {isMediumAndUp && (
                <>
                    <Box component="span" sx={{ flex: '0 0 2rem' }} />
                    <ToggleThemeButton
                        darkTheme={darkTheme}
                        lightTheme={lightTheme}
                    />
                    <Box component="span" sx={{ flex: '0 0 2rem' }} />
                </>
            )}
        </AppBar>
    );
};

export default CustomAppBar;
