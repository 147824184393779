import React from 'react';
import InsightsIcon from '@mui/icons-material/Insights';
import {
    Datagrid,
    NumberField,
    TextField,
    List,
    FilterList,
    FilterListItem,
    useListContext,
} from 'react-admin';
import { Typography, Card } from '@mui/material';
import { CustomPerPage } from '../common/custom_pagination';

const rowStyle = (record, index) => ({
    backgroundColor:
        record.aligned_pnl > 0 && index + 1
            ? record.aligned_pnl / record.absolute_positive_pnl_stop > 0.9 &&
              record.absolute_positive_pnl_stop != null
                ? 'rgba(148, 255, 167, 0.25)'
                : 'fff'
            : record.aligned_pnl / record.absolute_negative_pnl_stop > 0.9 &&
              record.absolute_negative_pnl_stop != null
            ? 'rgba(166, 8, 0, 0.25)'
            : 'fff',
});

function get_color(pl: number) {
    return pl >= 0 ? 'rgba(74, 182, 93, 1)' : 'rgba(166, 8, 0, 1)';
}

const FiltersAside = () => {
    const { data, isLoading } = useListContext();
    if (isLoading) return null;
    const currency_to_pnl = new Map<string, number>();
    const uniqueSubaccounts = new Set<string>();
    data.map(portfolio_launch => {
        if (!uniqueSubaccounts.has(portfolio_launch.portfolio_subaccounts)) {
            uniqueSubaccounts.add(portfolio_launch.portfolio_subaccounts);
            currency_to_pnl.set(
                portfolio_launch.currency,
                (currency_to_pnl.get(portfolio_launch.currency) ?? 0) +
                    portfolio_launch.aligned_pnl
            );
        }
    });
    const jsx_elements: JSX.Element[] = [];
    jsx_elements.push(
        <FilterList label="portfolio environment" icon={<InsightsIcon />}>
            <FilterListItem
                label="Live"
                value={{ portfolio_running_on_live: true }}
            />
            <FilterListItem
                label="Demo"
                value={{ portfolio_running_on_live: false }}
            />
        </FilterList>
    );
    const sorted_currency: Array<string> = [];
    currency_to_pnl.forEach((_: number, currency: string) => {
        sorted_currency.push(currency);
    });
    sorted_currency.sort((one, two) => (one > two ? -1 : 1)); // reverse
    sorted_currency.forEach((currency: string) => {
        jsx_elements.push(
            <Typography variant="h6" sx={{ paddingTop: 4 }}>
                Summary P&L {currency}
            </Typography>
        );
        jsx_elements.push(
            <Typography
                variant="h3"
                sx={{ color: get_color(currency_to_pnl.get(currency) ?? NaN) }}
            >
                {Math.round(
                    currency_to_pnl.get(currency) ?? NaN
                ).toLocaleString()}
            </Typography>
        );
        jsx_elements.push(
            <Typography variant="h6">
                Суммарный Daily PL по всем {currency} портфелям.
            </Typography>
        );
    });
    return <Card sx={{ padding: 1, margin: 1 }}>{jsx_elements}</Card>;
};

export const PortfolioPLList = () => {
    return (
        <List
            title="Portfolios report"
            pagination={false}
            perPage={CustomPerPage}
            empty={false}
            aside={<FiltersAside />}
            queryOptions={{ refetchInterval: 60 * 1000 }}
        >
            <Datagrid rowStyle={rowStyle} bulkActionButtons={false}>
                <TextField
                    source="portfolio_launch_code"
                    label="Portfolio launch code"
                />
                <NumberField
                    source="absolute_negative_pnl_stop"
                    label="Loss stop"
                    options={{ maximumFractionDigits: 0 }}
                    sortable={false}
                />
                <NumberField
                    source="aligned_pnl"
                    options={{ maximumFractionDigits: 0 }}
                    label="Daily PL"
                />
                <TextField source="currency" label="Currency" />
                <NumberField
                    source="absolute_positive_pnl_stop"
                    options={{ maximumFractionDigits: 0 }}
                    label="Profit stop"
                    sortable={false}
                />
                <NumberField
                    source="daily_pnl_percent"
                    options={{ maximumFractionDigits: 2 }}
                    label="PL % relative to today portfolio accounts initial money"
                />
                <NumberField
                    source="portfolio_pnl_percent"
                    label="PL % relative to portfolio money"
                    options={{ maximumFractionDigits: 2 }}
                />
                <NumberField source="total_money" label="Total money" />
                <TextField
                    source="portfolio_subaccounts"
                    label="Subaccounts"
                    sortable={false}
                />
            </Datagrid>
        </List>
    );
};
