import React from 'react';
import {
    Datagrid,
    EditButton,
    TextField,
    List,
    BulkDeleteButton,
} from 'react-admin';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

export const ManifestList = () => (
    <List pagination={<CustomPagination />} perPage={CustomPerPage}>
        <Datagrid
            bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
            rowClick="show"
        >
            <TextField source="id" />
            <TextField source="description" />
            <TextField source="kind" />
            <EditButton />
        </Datagrid>
    </List>
);
