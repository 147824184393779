import * as React from 'react';
import {
    Datagrid,
    List,
    TextField,
    TextInput,
    DateField,
    DateTimeInput,
    ReferenceField,
    BulkDeleteButton,
} from 'react-admin';

import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';

const listFilters = [
    <TextInput key="1" label="Instrument" source="instrument_name" alwaysOn />,
    <TextInput key="2" label="State" source="state_code" alwaysOn />,
    <DateTimeInput key="3" label="From" source="start_time" alwaysOn />,
    <DateTimeInput key="4" label="To" source="end_time" alwaysOn />,
];

export const SaxoTradingTimesList = () => (
    <List
        filters={listFilters}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
    >
        <Datagrid
            bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
        >
            <ReferenceField
                label="Instrument"
                source="instrument_id"
                reference="saxo_instrument_info"
                link="show"
            >
                <TextField source="instrument" />
            </ReferenceField>
            <TextField label="State" source="state" />
            <DateField label="Start" source="start_time" showTime />
            <DateField label="End" source="end_time" showTime />
        </Datagrid>
    </List>
);
