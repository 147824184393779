import * as React from 'react';
import {
    List,
    BooleanField,
    Datagrid,
    TextField,
    TextInput,
    useRecordContext,
} from 'react-admin';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import { JsonField } from 'react-admin-json-view';

const listFilters = [
    <TextInput
        key="instrument"
        label="Instrument"
        source="instrument"
        alwaysOn
    />,
    <TextInput key="donor_url" label="Donor URL" source="donor_url" alwaysOn />,
];

export const RefinitivDonorMonitorList = () => (
    <List
        filters={listFilters}
        sort={{ field: 'is_ok', order: 'DESC' }}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
    >
        <Datagrid
            rowClick="expand"
            expand={<RefinitivDonorMonitorShow />}
            bulkActionButtons={false}
        >
            <TextField source="donor_url" />
            <TextField source="instrument" />
            <BooleanField source="is_ok" />
            <TextField
                label="Last Update Time (UTC)"
                source="last_update_time"
            />
            <TextField source="last_error" />
            <TextField label="Last Error Time (UTC)" source="last_error_time" />
        </Datagrid>
    </List>
);

export const RefinitivDonorMonitorShow = () => {
    const record = useRecordContext();
    return (
        <div>
            <JsonField record={record} source="donor_element" />
        </div>
    );
};
