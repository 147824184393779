import React from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    regex,
    AutocompleteInput,
    ReferenceInput,
    SelectInput,
} from 'react-admin';

const validateSlug = regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Must be a slug');

export const RapidPortfolioLaunchCreate = () => (
    <Create title="Create portfolio launch." redirect="list">
        <SimpleForm>
            <ReferenceInput
                label="Portfolio"
                source="rapid_portfolio_id"
                reference="rapid_portfolio"
            >
                <AutocompleteInput
                    filterToQuery={searchText => ({ code__like: searchText })}
                    optionText="code"
                    optionValue="id"
                    validate={required()}
                    sx={{ width: '300px' }}
                />
            </ReferenceInput>
            <TextInput
                source="code"
                validate={[required(), validateSlug]}
                sx={{ width: '300px' }}
            />
            <TextInput source="description" sx={{ width: '300px' }} />
            <ReferenceInput
                label="Subaccount alias"
                source="subaccount_id"
                reference="subaccounts"
                perPage={300}
            >
                <AutocompleteInput
                    filterToQuery={searchText => ({
                        alias__icontains: searchText,
                    })}
                    optionText="alias"
                    optionValue="id"
                    sx={{ width: '300px' }}
                    validate={required()}
                />
            </ReferenceInput>
            <ReferenceInput
                label="Docker tag"
                source="docker_tag_id"
                reference="docker_tags"
            >
                <SelectInput
                    optionText="description"
                    validate={required()}
                    sx={{ width: '300px' }}
                />
            </ReferenceInput>
        </SimpleForm>
    </Create>
);
