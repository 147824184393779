import React, { Fragment } from 'react';
import {
    DateTimeInput,
    SelectInput,
    SimpleForm,
    TextInput,
    required,
    FormDataConsumer,
    NumberInput,
    Create,
} from 'react-admin';
import { trimSpaces } from './transformers';

const validateLoadFrom = (value, allValues) => {
    if (new Date(value) >= new Date(allValues.load_to)) {
        return 'Start Time should be less than End Time';
    }
    if (new Date(value) < new Date('1990-01-01')) {
        return 'Start Time should be after 1990-01-01';
    }
    return undefined;
};

const validateLoadTo = value => {
    if (new Date(value) >= new Date('2100-01-01')) {
        return 'End Time should be before 2100-01-01';
    }
    return undefined;
};

export const HistoryFetcherInstrumentCreate = () => (
    <Create title="Add instrument for downloading" transform={trimSpaces}>
        <SimpleForm>
            <TextInput source="name" validate={required()} />
            <NumberInput source="priority" validate={required()} />
            <SelectInput
                label="Broker"
                source="broker"
                choices={[
                    { id: 'exante', name: 'Exante' },
                    { id: 'saxo', name: 'Saxo' },
                    { id: 'interactive_brokers', name: 'IB' },
                    { id: 'trading_view', name: 'TV' },
                    { id: 'refinitiv', name: 'Refinitiv' },
                    { id: 'refinitiv_seconds', name: 'Refinitiv Seconds' },
                    { id: 'finam', name: 'Finam' },
                    { id: 'binance', name: 'Binance' },
                    { id: 'moex', name: 'MOEX' },
                    { id: 'cme', name: 'CME' },
                    { id: 'cbrf', name: 'CBRF' },
                ]}
                validate={required()}
                defaultValue={'exante'}
            />
            <FormDataConsumer>
                {({ formData }) =>
                    formData.broker === 'saxo' && (
                        <Fragment>
                            <SelectInput
                                label="Asset type"
                                source="asset_type"
                                choices={[
                                    {
                                        id: 'ContractFutures',
                                        name: 'ContractFutures',
                                    },
                                    { id: 'Stock', name: 'Stock' },
                                    { id: 'StockIndex', name: 'StockIndex' },
                                    { id: 'ManagedFund', name: 'ManagedFund' },
                                    { id: 'FxSpot', name: 'FxSpot' },
                                    {
                                        id: 'CfdOnFutures',
                                        name: 'CfdOnFutures',
                                    },
                                    { id: 'CfdOnStock', name: 'CfdOnStock' },
                                    { id: 'CfdOnIndex', name: 'CfdOnIndex' },
                                ]}
                                validate={required()}
                                defaultValue={'ContractFutures'}
                            />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) =>
                    formData.broker === 'binance' && (
                        <Fragment>
                            <SelectInput
                                label="Asset type"
                                source="asset_type"
                                choices={[
                                    { id: 'FUTURES', name: 'FUTURES' },
                                    { id: 'SPOT', name: 'SPOT' },
                                ]}
                                validate={required()}
                                defaultValue={'SPOT'}
                            />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
            <FormDataConsumer>
                {({ formData }) =>
                    formData.broker === 'trading_view' && (
                        <Fragment>
                            <TextInput label="Exchange" source="exchange" />
                        </Fragment>
                    )
                }
            </FormDataConsumer>
            <SelectInput
                label="Status"
                source="status"
                choices={[
                    { id: 'running', name: 'Running' },
                    { id: 'paused', name: 'Paused' },
                ]}
                validate={required()}
                defaultValue={'running'}
            />
            <SelectInput
                label="Tick type"
                source="tick_type"
                choices={[
                    { id: 'trades', name: 'Trades' },
                    { id: 'quotes', name: 'Quotes' },
                ]}
                validate={required()}
                defaultValue={'quotes'}
            />
            <DateTimeInput
                label="Start Time"
                source="load_from"
                validate={[required(), validateLoadFrom]}
            />
            <DateTimeInput
                label="End Time"
                source="load_to"
                validate={validateLoadTo}
            />
        </SimpleForm>
    </Create>
);
