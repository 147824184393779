import {
    Button,
    Confirm,
    useCreate,
    useNotify,
    useRecordContext,
    useRefresh,
} from 'react-admin';
import React, { useState } from 'react';
import StopIcon from '@mui/icons-material/Stop';
import StartIcon from '@mui/icons-material/Start';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';
import { useKeycloak } from '@react-keycloak/web';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

export const StopTradingButton = () => {
    const record = useRecordContext();
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const subaccount_id = record.id;
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'subaccounts/stop',
            {
                data: { id: subaccount_id },
            },
            {
                onError: error => {
                    notify(`Stop error: ${error.message}`, {
                        type: 'error',
                    });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Stopping trading.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<StopIcon />}
                label="Stop Trading"
                onClick={handleClick}
                disabled={isLoading}
                sx={{ color: 'red' }}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Stop trading on ${record.code}.`}
                content={`Do you want to stop trading on ${record.code}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const StartTradingButton = () => {
    const record = useRecordContext();
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const subaccount_id = record.id;
    const refresh = useRefresh();

    const handleDialogClose = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleConfirm = () => {
        create(
            'subaccounts/start',
            {
                data: { id: subaccount_id },
            },
            {
                onError: error => {
                    notify(`Start error: ${error.message}`, {
                        type: 'error',
                    });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Starting trading.');
                },
            }
        );
    };
    return (
        <>
            <Button
                startIcon={<StartIcon />}
                label="Start Trading"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Confirm
                isOpen={showDialog}
                loading={isLoading}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
                title={`Start trading on ${record.code}.`}
                content={`Do you want to start trading on ${record.code}?`}
                confirm="Yes"
                cancel="No"
                ConfirmIcon={DoneIcon}
                CancelIcon={CloseIcon}
            />
        </>
    );
};

export const SyncTokensAndSubaccounts = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);
    const { keycloak } = useKeycloak();

    const handleStart = () => {
        setLoading(true);
        fetch(`/api/sync_tokens_and_subaccounts`, {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                Authorization2: 'Bearer ' + keycloak.token,
            }),
        })
            .then(response => {
                if (response.status === 200) {
                    notify('Tokens and subaccounts were synchronized');
                } else {
                    notify('Synchronization: undetermined error', {
                        type: 'warning',
                    });
                }
            })
            .catch(e => {
                console.log(e);
                notify(`Connection error`, { type: 'warning' });
            })
            .finally(() => {
                setLoading(false);
            });
    };
    return (
        <>
            <Button
                variant="contained"
                startIcon={<PlayArrowIcon />}
                label="Synchronize"
                onClick={handleStart}
                disabled={loading}
            />
        </>
    );
};
