import { FinamInstrumentList } from './FinamInstrumentList';
import { FinamInstrumentInfoList } from './instrument_info/FinamInstrumentInfoList';
import { FinamInstrumentInfoShow } from './instrument_info/FinamInstrumentInfoShow';
import InstrumentsIcon from '@mui/icons-material/PlayArrow';
import InstrumentInfoIcon from '@mui/icons-material/LibraryBooks';
import DonorMonitorIcon from '@mui/icons-material/Visibility';
import { FinamInstrumentInfoCreate } from './instrument_info/FinamInstrumentInfoCreate';
import { FinamDonorMonitorList } from './FinamDonorMonitor';

export const finamInstruments = {
    icon: InstrumentsIcon,
    list: FinamInstrumentList,
};

export const finamInstrumentInfo = {
    icon: InstrumentInfoIcon,
    list: FinamInstrumentInfoList,
    show: FinamInstrumentInfoShow,
    create: FinamInstrumentInfoCreate,
};

export const finamDonorMonitor = {
    icon: DonorMonitorIcon,
    list: FinamDonorMonitorList,
};
