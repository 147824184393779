import * as React from 'react';
import {
    Show,
    SimpleShowLayout,
    TextField,
    ReferenceField,
    useRecordContext,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const MarketDataScheduleShow = () => {
    const record = useRecordContext();
    return (
        <Show>
            <SimpleShowLayout>
                <TextField source="id" />
                <ReferenceField
                    label="Instrument Group"
                    source="instrument_group_id"
                    reference="instrument_group"
                    resource="instrument_group_id"
                    sortable={false}
                >
                    <TextField source="code" />
                </ReferenceField>
                <JsonField record={record} source="market_data_schedule" />
            </SimpleShowLayout>
        </Show>
    );
};
