import React from 'react';
import {
    Datagrid,
    NumberField,
    TextField,
    DateField,
    List,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    DateTimeInput,
} from 'react-admin';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const listFilters = [
    <ReferenceInput
        key="subaccounts"
        label="Subaccount"
        source="broker_id"
        reference="subaccounts"
        alwaysOn
    >
        <AutocompleteInput
            optionText="code"
            sx={{ width: '250px' }}
            filterToQuery={searchText => ({
                code__icontains: searchText,
            })}
        />
    </ReferenceInput>,
    <TextInput key="order_id" label="Order ID" source="order_id" alwaysOn />,
    <TextInput
        key="oms_trade_id"
        label="OMS Trade ID"
        source="oms_trade_id"
        alwaysOn
    />,
    <TextInput
        key="symbol_id"
        label="Instrument"
        source="symbol_id__icontains"
        alwaysOn
    />,
    <DateTimeInput
        key="time_from"
        label="Time from"
        source="timestamp__gte"
        alwaysOn
    />,
    <DateTimeInput
        key="time_to"
        label="Time to"
        source="timestamp__lte"
        alwaysOn
    />,
    <TextInput
        key="broker_trade_id"
        label="Broker trade ID"
        source="broker_trade_id"
        alwaysOn
    />,
];

export const OMSTradesReportList = () => {
    return (
        <List
            title="OMSTrade"
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            filters={listFilters}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <TextField
                    source="subaccount_code"
                    label="Subaccount"
                    sortable={false}
                />
                <TextField source="order_id" label="Order ID" />
                <TextField source="oms_trade_id" />
                <TextField source="symbol_id" label="Instrument" />
                <NumberField source="size" />
                <NumberField
                    source="price"
                    options={{ maximumFractionDigits: 10 }}
                />
                <DateField
                    source="timestamp"
                    label="Time of trade"
                    showTime={true}
                />
                <TextField source="broker_trade_id" label="Broker trade ID" />
                <NumberField source="commission" />
                <DateField
                    source="created_at"
                    label="Created"
                    showTime={true}
                />
            </Datagrid>
        </List>
    );
};
