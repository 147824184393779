import InstrumentInfoIcon from '@mui/icons-material/LibraryBooks';
import { CQGInstrumentInfoList } from './instrument_info/CQGInstrumentInfoList';
import { CQGInstrumentInfoShow } from './instrument_info/CQGInstrumentInfoShow';
import { CQGInstrumentInfoCreate } from './instrument_info/CQGInstrumentInfoCreate';

export const cqgInstrumentInfo = {
    icon: InstrumentInfoIcon,
    list: CQGInstrumentInfoList,
    show: CQGInstrumentInfoShow,
    create: CQGInstrumentInfoCreate,
};
