import React from 'react';
import {
    BooleanField,
    Datagrid,
    DateField,
    NumberField,
    ShowButton,
    TextField,
    TextInput,
    List,
    BulkDeleteButton,
} from 'react-admin';
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded';
import CustomPagination, {
    CustomPerPage,
} from '../../../common/custom_pagination';

export const SaxoInstrumentInfoIcon = AssignmentRoundedIcon;

const listFilters = [
    <TextInput key="1" label="Instrument" source="instrument_name" alwaysOn />,
    <TextInput key="2" label="Exchange" source="exchange_name" alwaysOn />,
];

export const SaxoInstrumentInfoList = () => (
    <List
        filters={listFilters}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
        title={'Saxo Instrument Details'}
    >
        <Datagrid
            bulkActionButtons={<BulkDeleteButton mutationMode="pessimistic" />}
        >
            <TextField label="Instrument" source="instrument" />
            <TextField label="Description" source="description" />
            <TextField label="Asset Type" source="asset_type" />
            <NumberField label="Initial Margin" source="init_margin" />
            <NumberField label="Maintenance Margin" source="maint_margin" />
            <NumberField label="Commission" source="commission" />
            <NumberField label="Min Commission" source="min_commission" />
            <NumberField label="Exchange Fee" source="exchange_fee" />
            <NumberField label="Contract Size" source="contract_size" />
            <NumberField
                source="tick_size"
                options={{ maximumFractionDigits: 20 }}
            />
            <NumberField label="Max Price" source="max_price" />
            <TextField label="Exchange" source="exchange" />
            <DateField label="Expiry date" source="expiry_date" />
            <BooleanField label="Active" source="active" />
            <ShowButton />
        </Datagrid>
    </List>
);
