import React from 'react';
import { SelectInput, SimpleForm, TextInput, Create } from 'react-admin';
export const ManifestCreate = () => (
    <Create title="Add manifest">
        <SimpleForm>
            {/*<TextInput disabled source="id" />*/}
            <TextInput source="description" />
            <SelectInput
                source="kind"
                choices={[
                    { id: 'deployment', name: 'deployment' },
                    { id: 'job', name: 'job' },
                ]}
            />
            <TextInput source="yaml" fullWidth multiline />
        </SimpleForm>
    </Create>
);
