import React, { FC } from 'react';
import {
    Datagrid,
    TextField,
    DateField,
    List,
    EditButton,
    DeleteButton,
    BulkDeleteButton,
    ReferenceInput,
    AutocompleteInput,
    ReferenceArrayField,
    ExportButton,
    TopToolbar,
    ChipField,
    downloadCSV,
    SingleFieldList,
    CreateButton,
    BooleanField,
    DateTimeInput,
    TextInput,
    SelectInput,
    BooleanInput,
} from 'react-admin';
import jsonExport from 'jsonexport/dist';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import { Newspaper } from '@mui/icons-material';

export const CalendarEventIcon = Newspaper;

const listFilters = [
    <TextInput key="name" label="Name" source="name__icontains" alwaysOn />,
    <TextInput
        key="name_regexp"
        label="Name regular expression"
        source="name__regexp"
        alwaysOn
    />,
    <ReferenceInput
        key="instrument_group"
        label="Instrument Group"
        source="instrument_group_id"
        reference="instrument_group"
        alwaysOn
    >
        <AutocompleteInput
            optionText="code"
            sx={{ width: '250px' }}
            filterToQuery={searchText => ({
                code__icontains: searchText,
            })}
        />
    </ReferenceInput>,
    <SelectInput
        key="importance"
        label="Importance"
        source="importance__exact"
        choices={[
            { id: 'high', name: 'High' },
            { id: 'medium', name: 'Medium' },
            { id: 'low', name: 'Low' },
            { id: 'Holiday', name: 'Holiday' },
        ]}
        alwaysOn
    />,
    <SelectInput
        key="actual_and_expectation"
        label="Actual vs Expectation"
        source="actual_and_expectation__exact"
        choices={[
            { id: 'better', name: 'Better' },
            { id: 'worse', name: 'Worse' },
            { id: 'same', name: 'Same' },
        ]}
        alwaysOn
    />,
    <TextInput
        key="currency"
        label="Currency"
        source="currency__icontains"
        alwaysOn
    />,
    <TextInput key="zone" label="Zone" source="zone__icontains" alwaysOn />,
    <DateTimeInput key="3" label="After" source="from_time" alwaysOn />,
    <DateTimeInput key="4" label="Before" source="before_time" alwaysOn />,
    <BooleanInput
        source="is_all_day__exact"
        label="All day event"
        key="is_all_day"
        alwaysOn
    />,
];

const exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(records, 'instrument_groups', 'instrument_group').then(
        related_records => {
            const data = records.map(record => ({
                ...record,
                instrument_group_codes: record.instrument_groups.map(
                    in_gr => related_records[in_gr].code
                ),
            }));
            return jsonExport(
                data,
                {
                    headers: [
                        'date_time',
                        'name',
                        'instrument_group_codes',
                        'importance',
                        'currency',
                        'zone',
                        'actual',
                        'actual_and_expectation',
                        'forecast',
                        'previous',
                    ],
                },
                (err, csv) => {
                    downloadCSV(csv, 'calendar_events');
                }
            );
        }
    );
};

const CalendarEventListActions: FC = () => (
    <>
        <TopToolbar>
            <ExportButton maxResults={10000} />
            <CreateButton />
        </TopToolbar>
    </>
);

export const CalendarEventList = () => {
    return (
        <List
            title="Calendar events"
            sort={{ field: 'date_time', order: 'DESC' }}
            actions={<CalendarEventListActions />}
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            exporter={exporter}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField source="id" />
                <DateField source="date_time" label="Time" showTime />
                <TextField source="name" />
                <ReferenceArrayField
                    label="Instrument Groups"
                    source="instrument_groups"
                    reference="instrument_group"
                    sortable={false}
                >
                    <SingleFieldList>
                        <ChipField source="code" />
                    </SingleFieldList>
                </ReferenceArrayField>
                <TextField source="zone" />
                <TextField source="currency" />
                <TextField source="actual_and_expectation" sortable={false} />
                <TextField source="importance" />
                <TextField source="actual" sortable={false} />
                <TextField source="forecast" sortable={false} />
                <TextField source="previous" sortable={false} />
                <BooleanField source="is_all_day" />
                <EditButton />
                <DeleteButton mutationMode="pessimistic" />
            </Datagrid>
        </List>
    );
};
