import React, { FC } from 'react';
import {
    BooleanInput,
    BooleanField,
    SelectInput,
    TextField,
    TopToolbar,
    TextInput,
    CreateButton,
    List,
    BulkDeleteButton,
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const listFilters = [
    <TextInput key="instrument" label="Instrument" source="q" alwaysOn />,
];

const BinanceInstrumentForm: FC = () => (
    <RowForm>
        <TextField source="id" />
        <TextInput source="name" />
        <SelectInput
            source="price_type"
            choices={[{ id: 'mid_price', name: 'Mid Price' }]}
        />
        <BooleanInput source="is_active" />
    </RowForm>
);

const BinanceInstrumentListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

export const BinanceInstrumentList = () => {
    return (
        <List
            filters={listFilters}
            actions={<BinanceInstrumentListActions />}
            hasCreate
            empty={false}
            perPage={25}
        >
            <EditableDatagrid
                data-testid="binance_instrument-datagrid"
                rowClick="edit"
                createForm={<BinanceInstrumentForm />}
                editForm={<BinanceInstrumentForm />}
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
                mutationMode="pessimistic"
            >
                <TextField source="id" />
                <TextField source="name" />
                <TextField source="price_type" />
                <BooleanField source="is_active" />
            </EditableDatagrid>
        </List>
    );
};
