import Chip from '@mui/material/Chip';
import React from 'react';
import {
    TextField,
    ReferenceField,
    List,
    Datagrid,
    BooleanField,
    ReferenceInput,
    AutocompleteInput,
    NullableBooleanInput,
    DateField,
    TextInput,
    ArrayField,
    SingleFieldList,
    FunctionField,
} from 'react-admin';

const listFilters = [
    <ReferenceInput
        key="rapid_portfolio_launch"
        source="instrument_id"
        reference="instrument_mapping"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ instrument_code: searchText })}
            optionText="instrument_code"
            optionValue="id"
            sx={{ width: '250px' }}
        />
    </ReferenceInput>,
    <ReferenceInput
        key="rapid_portfolio_launch"
        source="rapid_portfolio_launch_id"
        reference="rapid_portfolio_launch"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ code__like: searchText })}
            optionText="code"
            optionValue="id"
            sx={{ width: '250px' }}
        />
    </ReferenceInput>,
    <ReferenceInput
        key="subaccounts"
        source="subaccount_id"
        reference="subaccounts"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ alias__icontains: searchText })}
            optionText="alias"
            optionValue="id"
            sx={{ width: '250px' }}
        />
    </ReferenceInput>,
    <TextInput
        key="tactic_type"
        label="Tactic Type"
        source="tactic_type"
        alwaysOn
    />,
    <NullableBooleanInput
        key="dry_mode"
        label="Dry mode"
        source="dry_mode"
        alwaysOn
    />,
];

export const ActiveRapidTraderMonitorList = () => {
    return (
        <List
            title="Active Rapid Traders Monitor"
            sort={{ field: 'id', order: 'DESC' }}
            pagination={false}
            filters={listFilters}
            actions={false}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="id" />
                <ReferenceField
                    source="subaccount_id"
                    label="Subaccount"
                    reference="subaccounts"
                    link={false}
                    sortable={true}
                >
                    <TextField source="alias" />
                </ReferenceField>
                <ReferenceField
                    source="rapid_portfolio_launch_id"
                    label="Rapid Portfolio Launch Code"
                    reference="rapid_portfolio_launch"
                    link={(record, reference) =>
                        `/${reference}/${record.id}/show/1`
                    }
                    sortable={true}
                >
                    <TextField source="code" />
                </ReferenceField>
                <ReferenceField
                    source="instrument_id"
                    label="Instrument"
                    reference="instrument_mapping"
                    link={false}
                    sortable={true}
                >
                    <TextField source="instrument_code" />
                </ReferenceField>
                <ReferenceField
                    source="instrument_id"
                    label="Switch Date"
                    reference="instrument_mapping"
                    link={false}
                    sortable={false}
                >
                    <DateField source="exante_switch_date" />
                </ReferenceField>
                <DateField source="expire_date" />
                <TextField source="tactic_type" />
                <ArrayField source="trading_days" sortable={false}>
                    <SingleFieldList linkType={false}>
                        <FunctionField
                            source="trading_days"
                            render={record => {
                                switch (record) {
                                    case 1: {
                                        return <Chip label="Понедельник" />;
                                    }
                                    case 2: {
                                        return <Chip label="Вторник" />;
                                    }
                                    case 3: {
                                        return <Chip label="Среда" />;
                                    }
                                    case 4: {
                                        return <Chip label="Четверг" />;
                                    }
                                    case 5: {
                                        return <Chip label="Пятница" />;
                                    }
                                    case 6: {
                                        return <Chip label="Суббота" />;
                                    }
                                    case 7: {
                                        return <Chip label="Воскресенье" />;
                                    }
                                }
                            }}
                            label="Trading Days"
                        />
                    </SingleFieldList>
                </ArrayField>
                <BooleanField source="dry_mode" />
            </Datagrid>
        </List>
    );
};
