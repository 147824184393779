import { IBInstrumentList } from './IBInstrumentList';
import InstrumentsIcon from '@mui/icons-material/PlayArrow';
import InstrumentInfoIcon from '@mui/icons-material/LibraryBooks';
import { IBInstrumentInfoList } from './instrument_info/IBInstrumentInfoList';
import { IBInstrumentInfoShow } from './instrument_info/IBInstrumentInfoShow';
import { IBInstrumentInfoCreate } from './instrument_info/IBInstrumentInfoCreate';

export const ibInstruments = {
    icon: InstrumentsIcon,
    list: IBInstrumentList,
};

export const ibInstrumentInfo = {
    icon: InstrumentInfoIcon,
    list: IBInstrumentInfoList,
    show: IBInstrumentInfoShow,
    create: IBInstrumentInfoCreate,
};
