import { RefinitivInstrumentList } from './RefinitivInstrumentList';
import InstrumentsIcon from '@mui/icons-material/PlayArrow';
import DonorMonitorIcon from '@mui/icons-material/Visibility';
import { RefinitivDonorMonitorList } from './RefinitivDonorMonitor';

export const refinitivInstruments = {
    icon: InstrumentsIcon,
    list: RefinitivInstrumentList,
};

export const refinitivDonorMonitor = {
    icon: DonorMonitorIcon,
    list: RefinitivDonorMonitorList,
};
