import React from 'react';
import InsightsIcon from '@mui/icons-material/Insights';
import {
    Datagrid,
    NumberField,
    TextField,
    List,
    FilterList,
    FilterListItem,
    useListContext,
    downloadCSV,
} from 'react-admin';
import { Typography, Card } from '@mui/material';
import jsonExport from 'jsonexport/dist';

const maxSubaccounts = 10000;

function get_color(pl: number) {
    return pl >= 0 ? 'rgba(74, 182, 93, 1)' : 'rgba(166, 8, 0, 1)';
}

const FiltersAside = () => {
    const { data, isLoading } = useListContext();
    if (isLoading) return null;
    const currency_to_pnl = new Map<string, number>();
    data.map(subaccount => {
        currency_to_pnl.set(
            subaccount.currency,
            (currency_to_pnl.get(subaccount.currency) ?? 0) +
                subaccount.daily_pnl
        );
    });
    const jsx_elements: JSX.Element[] = [];
    jsx_elements.push(
        <FilterList label="subaccount environment" icon={<InsightsIcon />}>
            <FilterListItem label="Live" value={{ is_live: true }} />
            <FilterListItem label="Demo" value={{ is_live: false }} />
        </FilterList>
    );
    const sorted_currency: Array<string> = [];
    currency_to_pnl.forEach((_: number, currency: string) => {
        sorted_currency.push(currency);
    });
    sorted_currency.sort((one, two) => (one > two ? -1 : 1)); // reverse
    sorted_currency.forEach((currency: string) => {
        jsx_elements.push(
            <Typography variant="h6" sx={{ paddingTop: 4 }}>
                Summary P&L {currency}
            </Typography>
        );
        jsx_elements.push(
            <Typography
                variant="h3"
                sx={{ color: get_color(currency_to_pnl.get(currency) ?? NaN) }}
            >
                {Math.round(
                    currency_to_pnl.get(currency) ?? NaN
                ).toLocaleString()}
            </Typography>
        );
        jsx_elements.push(
            <Typography variant="h6">
                Суммарный Daily PL по всем {currency} cубаккаунтам.
            </Typography>
        );
    });
    return <Card sx={{ padding: 1, margin: 1 }}>{jsx_elements}</Card>;
};

export const SubaccountPLList = () => {
    return (
        <List
            title="Subaccount report"
            pagination={false}
            perPage={maxSubaccounts}
            exporter={subaccount_pl_exporter}
            empty={false}
            aside={<FiltersAside />}
            queryOptions={{ refetchInterval: 60 * 1000 }}
        >
            <Datagrid bulkActionButtons={false}>
                <TextField source="subaccount_alias" label="Subaccount alias" />
                <TextField source="currency" label="Currency" />
                <NumberField
                    source="initial_money"
                    options={{ maximumFractionDigits: 0 }}
                    label="Initial money"
                />
                <NumberField
                    source="current_money"
                    label="Current money"
                    options={{ maximumFractionDigits: 0 }}
                />
                <NumberField
                    source="daily_pnl"
                    options={{ maximumFractionDigits: 0 }}
                    label="Daily PL"
                />
                <NumberField
                    source="daily_pnl_perc"
                    options={{ maximumFractionDigits: 2 }}
                    label="PL % relative to today's subaccount initial money"
                />
            </Datagrid>
        </List>
    );
};

const subaccount_pl_exporter = records => {
    jsonExport(
        records,
        {
            headers: [
                'subaccount_code',
                'initial_money',
                'current_money',
                'daily_pnl',
                'daily_pnl_perc',
            ],
        },
        (err, csv) => {
            downloadCSV(csv, 'subaccount_pl');
        }
    );
};
