import React from 'react';
import {
    Datagrid,
    DateField,
    SelectInput,
    DateInput,
    NumberField,
    ArrayField,
    TextField,
    List,
    useRecordContext,
    SingleFieldList,
    BulkDeleteButton,
} from 'react-admin';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const listFilters = [
    <SelectInput
        key="account_pattern"
        label="Account"
        source="account_pattern"
        choices={[
            { id: 'exante.ATC1307', name: 'Exante ATC1307' },
            { id: 'exante.AVF1295', name: 'Exante AVF1295' },
            { id: 'exante.WGZ2111', name: 'Exante WGZ2111' },
            { id: 'exante.YMP2003', name: 'Exante YMP2003' },
            { id: 'exante.KKJ2137', name: 'Exante KKJ2137' },
            { id: 'ib.U8345057', name: 'IB U8345057' },
            { id: 'ib.U5099110', name: 'IB U5099110' },
            { id: 'cqg.1877830', name: 'CQG 1877830' },
        ]}
        alwaysOn
    />,
    <DateInput
        key="from_date"
        source="from_date"
        alwaysOn
        inputProps={{ min: '1000-01-01', max: '9999-01-01' }}
    />,
    <DateInput
        key="to_date"
        source="to_date"
        alwaysOn
        inputProps={{ min: '1000-01-01', max: '9999-01-01' }}
    />,
    <SelectInput
        key="period"
        label="Period"
        source="period"
        choices={[
            { id: 60, name: '1 hour' },
            { id: 30, name: '30 minutes' },
            { id: 10, name: '10 minutes' },
        ]}
        alwaysOn
    />,
];

const DailyPnL = ({ label }) => {
    const record = useRecordContext();
    let color = 'lightgray';
    if (record && record.daily_pnl > 0) {
        color = 'lightgreen';
    } else if (record && record.daily_pnl < 0) {
        color = 'red';
    }
    return (
        <NumberField
            label={label}
            source="daily_pnl"
            style={{ color: color }}
        />
    );
};

const CurrentPnL = ({ label }) => {
    const record = useRecordContext();
    let color = 'lightgray';
    if (record && record.current_pnl > 0) {
        color = 'lightgreen';
    } else if (record && record.current_pnl < 0) {
        color = 'red';
    }
    return (
        <NumberField
            label={label}
            source="current_pnl"
            style={{ color: color }}
        />
    );
};

export const SummaryReportList = () => {
    return (
        <List
            title="Summary Report"
            filters={listFilters}
            sort={{ field: 'from_ts', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            empty={false}
            filterDefaultValues={{
                period: 60,
            }}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <DateField source="from_date" showTime />
                <NumberField source="initial_money" />
                <NumberField source="net_asset_value" />
                <CurrentPnL label="Current P&L" />
                <DailyPnL label="Daily P&L" />
                <NumberField
                    source="margin_utilization"
                    options={{ maximumFractionDigits: 2 }}
                />
                <NumberField
                    source="daily_pnl_percent"
                    options={{ maximumFractionDigits: 2 }}
                />
                <ArrayField source="instruments_in_day">
                    <SingleFieldList>
                        <TextField sx={{ mr: 1 }} source="instrument" />
                    </SingleFieldList>
                </ArrayField>
                <ArrayField source="instruments_in_position">
                    <SingleFieldList>
                        <TextField sx={{ mr: 1 }} source="instrument" />
                    </SingleFieldList>
                </ArrayField>
            </Datagrid>
        </List>
    );
};
