import React, { useState } from 'react';
import {
    Button,
    useNotify,
    Form,
    useRefresh,
    useCreate,
    TextInput,
    FileInput,
    FileField,
    SaveButton,
    required,
    SaveContextProvider,
    regex,
} from 'react-admin';
import PublishIcon from '@mui/icons-material/Publish';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconCancel from '@mui/icons-material/Cancel';
// import { useFormState } from 'react-hook-form';

const validateSlug = regex(/^[a-z0-9]+(?:-[a-z0-9]+)*$/, 'Must be a slug');

const RapidPortfolioCreateToolbar = props => {
    return (
        <>
            <Button
                label="ra.action.cancel"
                onClick={props.handleCloseClick}
                disabled={props.isLoading}
            >
                <IconCancel />
            </Button>
            <SaveButton label={'Create'} disabled={props.isLoading} />
        </>
    );
};

export const CreatePortfolioFromJSON = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [create, { isLoading }] = useCreate<any, Error>();
    const notify = useNotify();
    const refresh = useRefresh();

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };
    const saving = false;
    const mutationMode = 'pessimistic';

    const save = data => {
        create(
            'rapid_portfolio_from_json',
            {
                meta: {
                    files: data.portfolio_json,
                },
                data: {
                    code: data.code,
                    description: data.description ? data.description : '',
                },
            },
            {
                onError: error => {
                    notify(`Load error: ${error.message}`, { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Success!.');
                },
            }
        );
    };
    return (
        <>
            <Button
                variant="contained"
                startIcon={<PublishIcon />}
                label="Load from json"
                onClick={handleClick}
                disabled={isLoading}
            />
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Confirm Loading"
            >
                <DialogTitle> Portfolio creation</DialogTitle>

                <SaveContextProvider value={{ save, saving, mutationMode }}>
                    <Form>
                        <>
                            <DialogContent>
                                <div>
                                    <TextInput
                                        source="code"
                                        label="Portfolio code"
                                        validate={[required(), validateSlug]}
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                                <div>
                                    <TextInput
                                        source="description"
                                        label="Description"
                                        sx={{ width: '100%' }}
                                    />
                                </div>
                                <FileInput
                                    source="portfolio_json"
                                    label="Portfolio json"
                                    validate={required()}
                                >
                                    <FileField source="src" title="title" />
                                </FileInput>
                            </DialogContent>
                            <DialogActions>
                                <RapidPortfolioCreateToolbar
                                    isLoading={isLoading}
                                    handleCloseClick={handleCloseClick}
                                />
                            </DialogActions>
                        </>
                    </Form>
                </SaveContextProvider>
            </Dialog>
        </>
    );
};
