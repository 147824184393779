import React from 'react';
import {
    Create,
    required,
    SimpleForm,
    TextInput,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    DateTimeInput,
    BooleanInput,
} from 'react-admin';

export const CalendarEventCreate = () => (
    <Create title="Create an Calendar event" redirect="list">
        <SimpleForm>
            <DateTimeInput
                source="date_time"
                label="time"
                validate={required()}
            />
            <TextInput source="name" validate={required()} />
            <TextInput source="zone" />
            <TextInput source="currency" />
            <TextInput source="actual_and_expectation" />
            <TextInput source="importance" />
            <TextInput source="actual" />
            <TextInput source="forecast" />
            <TextInput source="previous" />
            <BooleanInput source="is_all_day" label="All day event" />
            <ReferenceArrayInput
                source="instrument_groups"
                reference="instrument_group"
            >
                <AutocompleteArrayInput
                    filterToQuery={searchText => ({
                        code__icontains: searchText,
                    })}
                    optionText="code"
                    label="instrument group code"
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Create>
);
