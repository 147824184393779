import React from 'react';
import {
    SimpleShowLayout,
    TextField,
    Show,
    useRecordContext,
} from 'react-admin';
export const ManifestTitle = () => {
    const record = useRecordContext();
    return <span>Manifest {record ? `"${record.description}"` : ''}</span>;
};

export const ManifestShow = () => (
    <Show title={<ManifestTitle />}>
        <SimpleShowLayout>
            <TextField source="id" />
            <TextField source="description" />
            <TextField source="kind" />
            <TextField source="yaml" component="pre" />
        </SimpleShowLayout>
    </Show>
);
