import * as React from 'react';
import { Layout, LayoutProps } from '@react-admin/ra-enterprise';
import AppBar from './AppBar';
import Menu from './Menu';
import CustomBreadcrumb from './Breadcrumb';

const OurLayout = (props: LayoutProps) => {
    return (
        <>
            <Layout
                {...props}
                appBar={AppBar}
                menu={Menu}
                breadcrumb={CustomBreadcrumb}
            />
        </>
    );
};

export default OurLayout;
