import { CalendarEventIcon, CalendarEventList } from './CalendarEventList';
import { CalendarEventEdit } from './CalendarEventEdit';
import { CalendarEventCreate } from './CalendarEventCreate';

export default {
    icon: CalendarEventIcon,
    list: CalendarEventList,
    edit: CalendarEventEdit,
    create: CalendarEventCreate,
};
