import ManifestIcon from '@mui/icons-material/LocalShipping';
import { ManifestList } from './ManifestList';
import { ManifestShow } from './ManifestShow';
import { ManifestEdit } from './ManifestEdit';
import { ManifestCreate } from './ManifestCreate';

export default {
    icon: ManifestIcon,
    list: ManifestList,
    show: ManifestShow,
    edit: ManifestEdit,
    create: ManifestCreate,
};
