import { regex } from 'react-admin';

export const validateNoSpaces = regex(
    /^[a-zA-Z0-9].+\S+$/,
    'Remove trailing spaces'
);
export const validateNoSpacesAnyAmount = regex(
    /^(?! )[а-яА-ЯA-Za-z0-9 ]*(?<! )$/,
    'Remove trailing or leading spaces'
);

export default { validateNoSpaces, validateNoSpacesAnyAmount };
