import { useKeycloak } from '@react-keycloak/web';
import jwt_decode from 'jwt-decode';

export const useAuthProvider = () => {
    const { keycloak } = useKeycloak();
    return {
        login: () => keycloak.login(),
        checkError: () => Promise.resolve(),
        checkAuth: () => {
            return keycloak.authenticated && keycloak.token
                ? Promise.resolve()
                : Promise.reject('Failed to obtain access token.');
        },
        logout: () => {
            sessionStorage.removeItem('token');
            if (keycloak.token) {
                return keycloak.logout();
            } else {
                return keycloak.login();
            }
        },
        getIdentity: () => {
            let token = keycloak.token;
            if (!token) {
                const token_from_storage = sessionStorage.getItem('token');
                if (token_from_storage) {
                    token = token_from_storage;
                }
            }
            if (token) {
                const decoded: any = jwt_decode(token);
                const id = decoded.sub;
                const fullName = decoded.name;
                keycloak.authenticated = true;
                keycloak.token = token;
                return Promise.resolve({ id, fullName });
            }
            return Promise.reject('Failed to get identity');
        },
        getPermissions: () => {
            if (keycloak.token) {
                const decoded: any = jwt_decode(keycloak.token);
                if (decoded.realm_access) {
                    return Promise.resolve(decoded.realm_access.roles);
                }
            }
            return Promise.resolve([]);
        },
    };
};

export default useAuthProvider;
