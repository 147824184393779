import React, { FC } from 'react';
import {
    TopToolbar,
    FilterButton,
    TextField,
    TextInput,
    CreateButton,
    Datagrid,
    List,
    BulkDeleteButton,
    useRecordContext,
    usePermissions,
    EditButton,
    DateField,
    FunctionField,
    NumberInput,
} from 'react-admin';

import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';
import { format } from 'date-fns';

export const DagsterQuotesIcon = SportsKabaddiIcon;
const listFilters = [
    <NumberInput key="id" label="ID" source="id" alwaysOn />,
    <TextInput key="user_tag" label="User Tag" source="user_tag" alwaysOn />,
];

const DagsterQuotesListActions: FC = () => {
    const { permissions } = usePermissions();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                {permissions &&
                    (permissions.includes('developer') ||
                        permissions.includes('manager')) && <CreateButton />}
            </TopToolbar>
        </>
    );
};

const QuoteField = () => {
    const record = useRecordContext();
    if (!record) return null;

    const currentTime = new Date();
    const customQuoteExpTime = new Date(record.custom_quote_exp_time);

    const quote =
        customQuoteExpTime > currentTime
            ? record.custom_quote
            : record.default_quote;

    return <span>{quote}</span>;
};

const DateTimeField = () => {
    const record = useRecordContext();
    if (!record) return null;
    if (!record.custom_quote_exp_time) return null;
    const customQuoteExpTime = new Date(record.custom_quote_exp_time);
    const formattedExpTime = format(customQuoteExpTime, 'yyyy-MM-dd HH:mm:ss');
    return <span>{formattedExpTime}</span>;
};

export const DagsterQuoteList = () => {
    const { permissions } = usePermissions();
    return (
        <List
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            sort={{ field: 'id', order: 'ASC' }}
            empty={false}
            actions={<DagsterQuotesListActions />}
            hasCreate
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField label="Id" source="id" />
                <TextField label="User Tag" source="user_tag" />
                <FunctionField
                    source={'default_quote'}
                    label="Quote"
                    render={QuoteField}
                />
                <DateField
                    source={'custom_quote_exp_time'}
                    transform={DateTimeField}
                    label="Custom Quote Expiration Time"
                    showTime={true}
                />
                {permissions &&
                    (permissions.includes('developer') ||
                        permissions.includes('manager')) && <EditButton />}
            </Datagrid>
        </List>
    );
};
