import React from 'react';
import {
    Edit,
    ReferenceArrayInput,
    AutocompleteArrayInput,
    SimpleForm,
    TextInput,
    required,
    DateTimeInput,
    BooleanInput,
} from 'react-admin';

export const CalendarEventEdit = () => (
    <Edit title="Edit an calendar event" mutationMode="pessimistic">
        <SimpleForm>
            <DateTimeInput
                source="date_time"
                label="time"
                validate={required()}
            />
            <TextInput source="name" />
            <TextInput source="zone" />
            <TextInput source="currency" />
            <TextInput source="actual_and_expectation" />
            <TextInput source="importance" />
            <TextInput source="actual" />
            <TextInput source="forecast" />
            <TextInput source="previous" />
            <BooleanInput source="is_all_day" label="All day event" />
            <ReferenceArrayInput
                source="instrument_groups"
                reference="instrument_group"
            >
                <AutocompleteArrayInput
                    filterToQuery={searchText => ({
                        code__icontains: searchText,
                    })}
                    optionText="code"
                    label="instrument group code"
                />
            </ReferenceArrayInput>
        </SimpleForm>
    </Edit>
);
