import React, { useState } from 'react';
import {
    Button,
    useNotify,
    useRefresh,
    useRecordContext,
    useUpdate,
    SimpleForm,
    SaveContextProvider,
    SaveButton,
    Toolbar,
} from 'react-admin';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { JsonInput } from 'react-admin-json-view';
import { IconButton } from '@mui/material';

const MarketDataScheduleEditToolbar = () => {
    return (
        <Toolbar>
            <SaveButton />
        </Toolbar>
    );
};

export const MarketDataScheduleEditButton = () => {
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate<any, Error>();
    const refresh = useRefresh();
    const record = useRecordContext();
    const saving = false;
    const mutationMode = 'pessimistic';
    const market_data_schedule_id = record.id;

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };
    const save = async values => {
        update(
            'market_data_schedule',
            { id: market_data_schedule_id, data: values },
            {
                onError: error => {
                    notify(`Modify error: ${error.message}`, { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Market data schedule is updated.');
                },
            }
        );
    };
    return (
        <>
            <Button
                variant="text"
                label="Edit config"
                onClick={handleClick}
                disabled={isLoading}
            >
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Confirm Copy"
            >
                <DialogTitle>
                    Create Trader Launch
                    <IconButton
                        onClick={handleCloseClick}
                        disabled={isLoading}
                        size="small"
                        sx={{
                            position: 'absolute',
                            right: 8,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <SaveContextProvider value={{ save, saving, mutationMode }}>
                    <SimpleForm toolbar={<MarketDataScheduleEditToolbar />}>
                        <JsonInput source="market_data_schedule" />
                    </SimpleForm>
                </SaveContextProvider>
            </Dialog>
        </>
    );
};
