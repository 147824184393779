import { DagsterQuoteList, DagsterQuotesIcon } from './DagsterQuoteList';
import { DagsterQuoteCreate } from './DagsterQuoteCreate';
import { DagsterQuoteEdit } from './DagsterQuoteEdit';

export const dagsterQuotes = {
    icon: DagsterQuotesIcon,
    list: DagsterQuoteList,
    create: DagsterQuoteCreate,
    edit: DagsterQuoteEdit,
};
