import React from 'react';
import { SelectInput, SimpleForm, TextInput, Edit } from 'react-admin';
import { ManifestTitle } from './ManifestShow';
export const ManifestEdit = () => (
    <Edit title={<ManifestTitle />} mutationMode="pessimistic">
        <SimpleForm>
            <TextInput disabled source="id" />
            <TextInput source="description" />
            <SelectInput
                source="kind"
                choices={[
                    { id: 'deployment', name: 'deployment' },
                    { id: 'job', name: 'job' },
                ]}
            />
            <TextInput source="yaml" multiline={true} fullWidth={true} />
        </SimpleForm>
    </Edit>
);
