import React from 'react';
import {
    BooleanField,
    DateField,
    NumberField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
} from 'react-admin';

export const ExanteInstrumentInfoShow = () => (
    <Show>
        <TabbedShowLayout>
            <Tab label="Full Info">
                <TextField source="instrument" />
                <TextField source="description" />
                <NumberField
                    source="tick_size"
                    options={{ maximumFractionDigits: 20 }}
                />
                <NumberField
                    source="tick_price"
                    options={{ maximumFractionDigits: 10 }}
                />
                <TextField
                    label="Tick Price Currency"
                    source="tick_price_currency"
                />
                <TextField
                    label="Tick Price Currency Broker"
                    source="tick_price_currency_broker"
                />
                <NumberField source="init_margin" />
                <NumberField
                    label="Commission"
                    source="commission"
                    options={{ maximumFractionDigits: 20 }}
                />
                <NumberField source="contract_size" />
                <NumberField source="max_price" />
                <TextField source="asset_type" />
                <TextField source="exchange" />
                <BooleanField source="active" />
                <DateField source="expiry_date" />
                <DateField source="created_at" />
            </Tab>
        </TabbedShowLayout>
    </Show>
);
