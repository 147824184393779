import React, { FC } from 'react';
import {
    BooleanInput,
    BooleanField,
    NumberInput,
    NumberField,
    TextField,
    TopToolbar,
    TextInput,
    CreateButton,
    List,
    BulkDeleteButton,
} from 'react-admin';

import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const listFilters = [
    <TextInput key="instrument" label="Instrument" source="q" alwaysOn />,
];

const SaxoInstrumentForm: FC = () => (
    <RowForm>
        <TextField source="id" />
        <TextInput source="instrument" />
        <NumberInput source="compression" />
        <TextInput source="asset_type" />
        <BooleanInput source="is_active" />
    </RowForm>
);

const SaxoInstrumentListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

export const SaxoInstrumentList = () => {
    return (
        <List
            filters={listFilters}
            actions={<SaxoInstrumentListActions />}
            hasCreate
            empty={false}
            perPage={25}
        >
            <EditableDatagrid
                data-testid="saxo_instrument-datagrid"
                rowClick="edit"
                createForm={<SaxoInstrumentForm />}
                editForm={<SaxoInstrumentForm />}
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
                mutationMode="pessimistic"
            >
                <TextField source="id" />
                <TextField source="instrument" />
                <NumberField source="compression" />
                <TextField source="asset_type" />
                <BooleanField source="is_active" />
            </EditableDatagrid>
        </List>
    );
};
