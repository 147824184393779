import React from 'react';
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    useRecordContext,
    Labeled,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';

export const RapidPortfolioConfigShow = () => {
    const record = useRecordContext();
    return (
        <div>
            <Labeled label="Rapid Portfolio Config">
                <JsonField record={record} source="config_json" />
            </Labeled>
        </div>
    );
};

export const RapidPortfolioShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="description" />
                </Tab>
                <Tab label="Rapid Portfolio Config">
                    <RapidPortfolioConfigShow />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
