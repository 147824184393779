import React from 'react';
import { SimpleForm, TextInput, Create, required, regex } from 'react-admin';

export const validateUserTag = regex(
    /^[a-zA-Z0-9_.-]+$/,
    'Invalid tag format. Only alphanumeric characters, underscores, hyphens, and periods are allowed.'
);

export const DagsterQuoteCreate = () => (
    <Create title="Add Dagster User Tag" redirect="list">
        <SimpleForm>
            <TextInput
                source="user_tag"
                validate={[validateUserTag, required()]}
            />
        </SimpleForm>
    </Create>
);
