import React, { FC } from 'react';
import {
    Datagrid,
    DateField,
    NumberField,
    ShowButton,
    TextField,
    TextInput,
    TopToolbar,
    BooleanInput,
    DateTimeInput,
    FilterButton,
    CloneButton,
    List,
} from 'react-admin';
import CustomPagination, {
    CustomPerPage,
} from '../../../common/custom_pagination';

const IBInstrumentInfoListActions: FC = () => (
    <>
        <TopToolbar>
            <FilterButton />
        </TopToolbar>
    </>
);

const listFilters = [
    <TextInput key="1" label="Instrument" source="instrument_name" alwaysOn />,
    <TextInput key="2" label="Exchange" source="exchange_name" alwaysOn />,
    <BooleanInput key="3" label="Show Actual" source="actual" />,
    <DateTimeInput key="4" label="Actual At" source="actual_datetime" />,
    <DateTimeInput key="5" label="Actual From" source="actual_from" />,
    <DateTimeInput key="6" label="Actual To" source="actual_to" />,
];

export const IBInstrumentInfoList = () => (
    <List
        filters={listFilters}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
        title={'IB Instrument Details'}
        actions={<IBInstrumentInfoListActions />}
    >
        <Datagrid bulkActionButtons={false}>
            <TextField label="Instrument" source="instrument" />
            <TextField label="Description" source="description" />
            <TextField label="Asset Type" source="asset_type" />
            <NumberField label="Initial Margin" source="init_margin" />
            <NumberField
                label="Commission"
                source="commission"
                options={{ maximumFractionDigits: 20 }}
            />
            <NumberField label="Contract Size" source="contract_size" />
            <NumberField
                source="tick_size"
                options={{ maximumFractionDigits: 20 }}
            />
            <NumberField
                source="tick_price"
                options={{ maximumFractionDigits: 10 }}
            />
            <TextField
                label="Tick Price Currency"
                source="tick_price_currency"
            />
            <TextField
                label="Tick Price Currency Broker"
                source="tick_price_currency_broker"
                sortable={false}
            />
            <NumberField label="Max Price" source="max_price" />
            <TextField label="Exchange" source="exchange" />
            <DateField label="Expiry date" source="expiry_date" />
            <DateField label="Created At" source="created_at" showTime />
            <DateField label="Changed At" source="changed_at" showTime />
            <ShowButton />
            <CloneButton />
        </Datagrid>
    </List>
);
