import React from 'react';
import {
    DateTimeInput,
    required,
    SimpleForm,
    Create,
    ReferenceInput,
    NumberInput,
    AutocompleteInput,
} from 'react-admin';

export const FundMovementCreate = () => (
    <Create>
        <SimpleForm>
            <ReferenceInput
                label="Alias"
                source="subaccount"
                reference="subaccounts"
            >
                <AutocompleteInput
                    optionText="alias"
                    sx={{ width: '250px' }}
                    filterToQuery={searchText => ({
                        alias__icontains: searchText,
                    })}
                    validate={[required()]}
                />
            </ReferenceInput>
            <NumberInput
                label="Transaction value"
                source="fund_move"
                validate={[required()]}
                sx={{ width: '250px' }}
            />
            <DateTimeInput
                label="Transaction date"
                source="moved_at"
                sx={{ width: '250px' }}
                validate={[required()]}
            />
        </SimpleForm>
    </Create>
);
