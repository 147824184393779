import { ExanteInstrumentList } from './ExanteInstrumentList';
import { ExanteInstrumentInfoList } from './instrument_info/ExanteInstrumentInfoList';
import { ExanteInstrumentInfoShow } from './instrument_info/ExanteInstrumentInfoShow';
import InstrumentsIcon from '@mui/icons-material/PlayArrow';
import InstrumentInfoIcon from '@mui/icons-material/LibraryBooks';
import DonorMonitorIcon from '@mui/icons-material/Visibility';
import { ExanteInstrumentInfoCreate } from './instrument_info/ExanteInstrumentInfoCreate';
import { ExanteDonorMonitorList } from './ExanteDonorMonitor';

export const exanteInstruments = {
    icon: InstrumentsIcon,
    list: ExanteInstrumentList,
};

export const exanteInstrumentInfo = {
    icon: InstrumentInfoIcon,
    list: ExanteInstrumentInfoList,
    show: ExanteInstrumentInfoShow,
    create: ExanteInstrumentInfoCreate,
};

export const exanteDonorMonitor = {
    icon: DonorMonitorIcon,
    list: ExanteDonorMonitorList,
};
