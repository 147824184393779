import {
    MarketDataScheduleList,
    MarketDataScheduleIcon,
} from './MarketDataScheduleList';
import { MarketDataScheduleCreate } from './MarketDataScheduleCreate';
import { MarketDataScheduleShow } from './MarketDataScheduleShow';

export default {
    icon: MarketDataScheduleIcon,
    list: MarketDataScheduleList,
    create: MarketDataScheduleCreate,
    show: MarketDataScheduleShow,
};
