import { RapidPortfolioList } from './rapid_portfolio/RapidPortfolioList';
import { RapidPortfolioShow } from './rapid_portfolio/RapidPortfolioShow';
import { RapidPortfolioLaunchList } from './rapid_portfolio_launch/RapidPortfolioLaunchList';
import { RapidPortfolioLaunchCreate } from './rapid_portfolio_launch/RapidPortfolioLaunchCreate';
import { RapidPortfolioLaunchShow } from './rapid_portfolio_launch/RapidPortfolioLaunchShow';
import AccessibleForwardIcon from '@mui/icons-material/AccessibleForward';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import AssignmentIcon from '@mui/icons-material/Assignment';
import DvrIcon from '@mui/icons-material/Dvr';
import { RapidTraderList } from './rapid_trader/RapidTraderList';
import { RapidTraderEdit } from './rapid_trader/RapidTraderEdit';
import { RapidPortfolioLaunchEdit } from './rapid_portfolio_launch/RapidPortfolioLaunchEdit';
import { RapidPortfolioLaunchScheduleList } from './rapid_portfolio_launch_schedule/RapidPortfolioLaunchScheduleList';
import { RapidPortfolioLaunchConfigList } from './rapid_portfolio_launch_config/RapidPortfolioLaunchConfigList';
import { RapidTraderShow } from './rapid_trader/RapidTraderShow';
import { ActiveRapidTraderMonitorList } from './active_rapid_trader_monitor/ActiveRapidTraderMonitorList';

export const rapid_portfolio = {
    icon: AccessibleForwardIcon,
    list: RapidPortfolioList,
    show: RapidPortfolioShow,
};

export const rapid_portfolio_launch = {
    icon: RocketLaunchIcon,
    list: RapidPortfolioLaunchList,
    create: RapidPortfolioLaunchCreate,
    show: RapidPortfolioLaunchShow,
    edit: RapidPortfolioLaunchEdit,
};

export const rapid_trader = {
    list: RapidTraderList,
    icon: RocketLaunchIcon,
    edit: RapidTraderEdit,
    show: RapidTraderShow,
};

export const rapid_portfolio_launch_schedule = {
    icon: WorkHistoryIcon,
    list: RapidPortfolioLaunchScheduleList,
};

export const rapid_portfolio_launch_config = {
    icon: AssignmentIcon,
    list: RapidPortfolioLaunchConfigList,
};

export const active_rapid_trader_monitor = {
    list: ActiveRapidTraderMonitorList,
    icon: DvrIcon,
};
