import {
    InstrumentGroupList,
    InstrumentGroupIcon,
} from './InstrumentGroupList';
import { InstrumentGroupCreate } from './InstrumentGroupCreate';
import { InstrumentGroupEdit } from './InstrumentGroupEdit';

export default {
    icon: InstrumentGroupIcon,
    list: InstrumentGroupList,
    create: InstrumentGroupCreate,
    edit: InstrumentGroupEdit,
};
