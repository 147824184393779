import React from 'react';
import {
    Datagrid,
    TextField,
    List,
    SelectInput,
    ReferenceField,
    BulkDeleteButton,
    ReferenceInput,
} from 'react-admin';

import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const filters = [
    <SelectInput
        key="severity"
        label="Severity"
        source="severity"
        choices={[
            { id: 'warning', name: 'Warnings' },
            { id: 'error', name: 'Errors' },
        ]}
        alwaysOn
    />,
    <ReferenceInput
        key="error_code_id"
        label="Error code"
        source="error_code_id"
        reference="report_errors"
        alwaysOn
    >
        <SelectInput optionText="error_code" optionValue="id" />
    </ReferenceInput>,
];

export const InstrumentMappingErrors = () => {
    return (
        <List
            title="Instrument mapping errors"
            empty={false}
            filters={filters}
            sort={{ field: 'severity', order: 'ASC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField source="instrument_code" sortable={false} />
                <TextField source="instrument_group" sortable={false} />
                <TextField source="severity" />
                <ReferenceField
                    label="Error code"
                    source="error_code_id"
                    reference="report_errors"
                >
                    <TextField source="error_code" />
                </ReferenceField>
                <TextField source="error_description" sortable={false} />
            </Datagrid>
        </List>
    );
};
