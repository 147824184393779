import React, { FC } from 'react';
import {
    Datagrid,
    TextField,
    List,
    TopToolbar,
    CreateButton,
    DeleteButton,
    ReferenceField,
    AutocompleteInput,
    ReferenceInput,
    BulkDeleteButton,
    ShowButton,
} from 'react-admin';

import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import { MarketDataScheduleEditButton } from './Buttons';

export const MarketDataScheduleIcon = AccessTimeIcon;

const listFilters = [
    <ReferenceInput
        key="instrument_group"
        label="Instrument Group"
        source="instrument_group_id"
        reference="instrument_group"
        alwaysOn
    >
        <AutocompleteInput
            optionText="code"
            sx={{ width: '250px' }}
            filterToQuery={searchText => ({
                code__icontains: searchText,
            })}
        />
    </ReferenceInput>,
];

const MarketDataScheduleListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

export const MarketDataScheduleList = () => {
    return (
        <List
            title="Market Data Schedule"
            sort={{ field: 'id', order: 'DESC' }}
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<MarketDataScheduleListActions />}
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField source="id" />
                <ReferenceField
                    label="Instrument Group"
                    source="instrument_group_id"
                    reference="instrument_group"
                    resource="instrument_group_id"
                    sortable={false}
                >
                    <TextField source="code" />
                </ReferenceField>
                <MarketDataScheduleEditButton />
                <ShowButton />
                <DeleteButton mutationMode="pessimistic" />
            </Datagrid>
        </List>
    );
};
