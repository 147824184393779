import React, { FC } from 'react';
import {
    TextField,
    List,
    BooleanField,
    TextInput,
    TopToolbar,
    FilterButton,
    Datagrid,
    SelectInput,
} from 'react-admin';

import AddCardIcon from '@mui/icons-material/AddCard';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';
import { StopTradingButton, StartTradingButton } from './Buttons';

const listFilters = [
    <TextInput
        key="code"
        label="Subaccount"
        source="code__icontains"
        alwaysOn
    />,
    <TextInput key="alias" label="Alias" source="alias__icontains" alwaysOn />,
    <SelectInput
        key="trading_allowed"
        label="Trading allowed"
        source="trading_allowed"
        choices={[
            { id: true, name: 'Allowed' },
            { id: false, name: 'Forbidden' },
        ]}
        alwaysOn
    />,
];

export const SubaccountIcon = AddCardIcon;

const SubaccountListActions: FC = () => (
    <>
        <TopToolbar>
            <FilterButton />
        </TopToolbar>
    </>
);

export const SubaccountList = () => {
    return (
        <List
            title="Trader Launches"
            filters={listFilters}
            sort={{ field: 'trading_allowed', order: 'DESC' }}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<SubaccountListActions />}
        >
            <SubaccountDataGrid />
        </List>
    );
};

export const SubaccountDataGrid = () => {
    return (
        <Datagrid bulkActionButtons={false}>
            <TextField source="code" label="Subaccount" />
            <TextField source="alias" label="Alias" />
            <TextField source="currency" label="Currency" />
            <BooleanField source="trading_allowed" label="Trading allowed" />
            <StopTradingButton />
            <StartTradingButton />
        </Datagrid>
    );
};
