import {
    Datagrid,
    NumberField,
    TextField,
    TextInput,
    List,
    useRecordContext,
} from 'react-admin';
import React from 'react';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';
import { JsonField } from 'react-admin-json-view';

const listFilters = [
    <TextInput
        key="instrument"
        label="Instrument"
        source="instrument"
        alwaysOn
    />,
];

export const SaxoDonorMonitorList = () => (
    <List
        filters={listFilters}
        sort={{ field: 'last_error_datetime', order: 'DESC' }}
        pagination={<CustomPagination />}
        perPage={CustomPerPage}
    >
        <Datagrid
            rowClick="expand"
            expand={<SaxoDonorMonitorShow />}
            bulkActionButtons={false}
        >
            <TextField label="Id" source="id" />
            <TextField source="instrument" />
            <TextField source="timeframe" />
            <NumberField source="compression" />
            <TextField
                label="Last Error Time (UTC)"
                source="last_error_datetime"
            />
            <TextField source="last_error_message" />
        </Datagrid>
    </List>
);

export const SaxoDonorMonitorShow = () => {
    const record = useRecordContext();
    return (
        <div>
            <JsonField record={record} source="donor_element" />
        </div>
    );
};
