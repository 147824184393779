import React from 'react';
import { Create, required, SimpleForm, TextInput } from 'react-admin';
import { validateNoSpacesAnyAmount } from '../../common/validators';

export const InstrumentGroupCreate = () => (
    <Create title="Create an Instrument Group" redirect="list">
        <SimpleForm>
            <TextInput
                source="code"
                validate={[validateNoSpacesAnyAmount, required()]}
            />
        </SimpleForm>
    </Create>
);
