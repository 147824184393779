import React from 'react';
import {
    List,
    Datagrid,
    DateField,
    TextField,
    ReferenceField,
    TextInput,
    ReferenceInput,
    AutocompleteInput,
    useRecordContext,
    Labeled,
    downloadCSV,
    DateTimeInput,
} from 'react-admin';
import { JsonField } from 'react-admin-json-view';
import jsonExport from 'jsonexport/dist';
import CustomPagination, {
    CustomPerPage,
} from '../../common/custom_pagination';

const listFilters = [
    <ReferenceInput
        key="rapid_portfolio_launch_id"
        label="Rapid Portfolio Launch"
        source="rapid_portfolio_launch_id"
        reference="rapid_portfolio_launch"
        alwaysOn
    >
        <AutocompleteInput
            filterToQuery={searchText => ({ code__like: searchText })}
            optionText="code"
            optionValue="id"
            sx={{ width: '250px' }}
        />
    </ReferenceInput>,
    <TextInput
        key="author__like"
        label="Author"
        source="author__like"
        alwaysOn
    />,
    <DateTimeInput key="3" label="From" source="start_date" alwaysOn />,
    <DateTimeInput key="4" label="To" source="end_date" alwaysOn />,
];

export const RapidPortfolioLaunchConfigList = () => (
    <List
        sort={{ field: 'created_at', order: 'desc' }}
        filters={listFilters}
        exporter={event_exporter}
        perPage={CustomPerPage}
        pagination={<CustomPagination />}
    >
        <EventDatagrid />
    </List>
);

const EventDatagrid = () => {
    return (
        <Datagrid bulkActionButtons={false} expand={ConfigShow}>
            <ReferenceField
                label="Rapid Portfolio Launch"
                source="rapid_portfolio_launch_id"
                reference="rapid_portfolio_launch"
                sortable={false}
            >
                <TextField source="code" />
            </ReferenceField>
            <TextField source="author" />
            <DateField source="created_at" showTime />
            <DateField source="updated_at" showTime />
        </Datagrid>
    );
};

const ConfigShow = () => {
    const record = useRecordContext();
    return (
        <div>
            <div>
                <Labeled label="Config diff">
                    <JsonField record={record} source="action" />
                </Labeled>
            </div>
            <div>
                <Labeled label="Rapid Portfolio Config">
                    <JsonField
                        record={record}
                        source="config_json"
                        reactJsonOptions={{ collapsed: true }}
                    />
                </Labeled>
            </div>
        </div>
    );
};

const event_exporter = (records, fetchRelatedRecords) => {
    fetchRelatedRecords(
        records,
        'rapid_portfolio_launch_id',
        'rapid_portfolio_launch'
    ).then(rapid_portfolio_launches => {
        const configsForExport = records.map(record => {
            const data_with_code = {
                ...record,
                rapid_portfolio_launch:
                    rapid_portfolio_launches[record.rapid_portfolio_launch_id]
                        .code,
            };
            const { action, id, rapid_portfolio_launch_id, ...dataForExport } =
                data_with_code;
            dataForExport.config_json = JSON.stringify(
                dataForExport.config_json
            );
            return dataForExport;
        });
        jsonExport(
            configsForExport,
            {
                headers: [
                    'created_at',
                    'rapid_portfolio_launch',
                    'author',
                    'config_json',
                ],
            },
            (err, csv) => {
                downloadCSV(csv, 'rapid_portfolio_launch_config');
            }
        );
    });
};
