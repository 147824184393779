import React from 'react';
import {
    Show,
    TextField,
    TabbedShowLayout,
    Tab,
    ReferenceField,
    useRecordContext,
} from 'react-admin';

const ShowBarsIndicators = () => {
    const record = useRecordContext();
    const port = 5000;
    const path =
        window.location.protocol +
        `//${window.location.hostname}` +
        (window.location.host === window.location.hostname ? '' : `:${port}`) +
        `/api/rapid_trader_visual?rapid_trader_id=${record.id}`;
    return (
        <div>
            <iframe
                title="Trader Bars and Indicators"
                style={{
                    overflow: 'auto',
                    display: 'flex',
                    position: 'relative',
                    width: '100%',
                    height: '100%',
                    minHeight: '670px',
                }}
                src={path}
            ></iframe>
        </div>
    );
};

export const RapidTraderShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField source="id" />
                    <TextField source="instrument_group" />
                    <ReferenceField
                        source="rapid_portfolio_launch_id"
                        label="Rapid Portfolio Launch Code"
                        reference="rapid_portfolio_launch"
                        link="show"
                        sortable={false}
                    />
                </Tab>
                <Tab label="Show bars and indicators">
                    <ShowBarsIndicators />
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};
