import React, { FC } from 'react';
import {
    required,
    TextField,
    TopToolbar,
    TextInput,
    CreateButton,
    List,
    BulkDeleteButton,
} from 'react-admin';
import { validateNoSpaces } from '../common/validators';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';

const DockerTagListForm: FC = () => (
    <RowForm>
        <TextField source="id" />
        <TextInput
            source="docker_tag"
            validate={[validateNoSpaces, required()]}
        />
        <TextInput source="description" validate={required()} />
    </RowForm>
);

const DockerTagListActions: FC = () => (
    <>
        <TopToolbar>
            <CreateButton />
        </TopToolbar>
    </>
);

export const DockerTagList = () => {
    return (
        <List
            actions={<DockerTagListActions />}
            hasCreate
            empty={false}
            perPage={25}
        >
            <EditableDatagrid
                data-testid="docker-tag-datagrid"
                rowClick="edit"
                createForm={<DockerTagListForm />}
                editForm={<DockerTagListForm />}
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
                mutationMode="pessimistic"
            >
                <TextField source="id" />
                <TextField source="docker_tag" />
                <TextField source="description" />
            </EditableDatagrid>
        </List>
    );
};
