import React, { useState } from 'react';
import {
    Button,
    useNotify,
    useRefresh,
    useRecordContext,
    useUpdate,
    SaveContextProvider,
    Loading,
    SimpleForm,
    Toolbar,
    SaveButton,
} from 'react-admin';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { JsonInput } from 'react-admin-json-view';
import IconCancel from '@mui/icons-material/Cancel';

const RapidTraderConfigEditToolbar = props => {
    return (
        <Toolbar sx={{ position: 'sticky', bottom: 0, zIndex: 2 }}>
            <SaveButton />
            <Button
                label="ra.action.cancel"
                onClick={props.onClick}
                disabled={props.disabled}
                sx={{
                    position: 'absolute',
                    right: 8,
                }}
                color="error"
            >
                <IconCancel />
            </Button>
        </Toolbar>
    );
};

export const RapidTraderConfigEdit = () => {
    const [showDialog, setShowDialog] = useState(false);
    const notify = useNotify();
    const [update, { isLoading }] = useUpdate<any, Error>();
    const refresh = useRefresh();
    const record = useRecordContext();
    const saving = false;
    const mutationMode = 'pessimistic';
    const rapid_trader_id = record.id;

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    if (isLoading) return <Loading />;
    const save = async values => {
        update(
            'rapid_trader_config',
            { id: rapid_trader_id, data: values },
            {
                onError: error => {
                    notify(`Modify error: ${error.message}`, { type: 'error' });
                },
                onSuccess: () => {
                    setShowDialog(false);
                    refresh();
                    notify('Config is updated.');
                },
            }
        );
    };
    return (
        <>
            <Button
                variant="text"
                label="Edit config"
                onClick={handleClick}
                disabled={isLoading}
            >
                <EditIcon />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Confirm Copy"
            >
                <DialogTitle>Edit config</DialogTitle>
                <SaveContextProvider value={{ save, saving, mutationMode }}>
                    <SimpleForm
                        toolbar={
                            <RapidTraderConfigEditToolbar
                                disabled={isLoading}
                                onClick={handleCloseClick}
                            />
                        }
                    >
                        <JsonInput source="config_json" />
                    </SimpleForm>
                </SaveContextProvider>
            </Dialog>
        </>
    );
};
