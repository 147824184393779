import React from 'react';
import {
    Edit,
    SimpleForm,
    ReferenceField,
    BooleanInput,
    required,
} from 'react-admin';
import { RapidPortfolioConfigEdit } from './Buttons';

export const RapidPortfolioLaunchEdit = () => (
    <Edit title="Edit rapid portfolio launch" mutationMode="pessimistic">
        <SimpleForm>
            <ReferenceField
                label={false}
                source="id"
                reference="rapid_portfolio_config"
                sortable={false}
            >
                <RapidPortfolioConfigEdit />
            </ReferenceField>
            <BooleanInput source="is_live" validate={required()} />
            <BooleanInput
                source="use_quotes"
                validate={required()}
                title={'Использовать quote тики'}
            />
            <BooleanInput
                source="rapid_bar_collector"
                validate={required()}
                title={'Использовать rapid bar collector'}
                defaultValue={false}
            />
        </SimpleForm>
    </Edit>
);
